import { QuickPostTypeListOutputModel } from "app/Models/quickpostlist-model.model"
export class QuickPostModel {
    UserQuickPostId: number;
    UserDetailsId: number;
    QuickPostTypeId: number;
    MoodId: number;
    MoodRatingId: number;
    Title: string;
    DiaryDate: Date;
    DiaryTimeFrom: Date;
    DiaryTimeTo: Date;
    PostContent: string;
    Location: string;
    TagCategory: string;
    Latitude: number;
    Longitude: number;
}

export class MoodRatingListOutputModel
{
    MoodRatingId: number;
    MoodRatingName: string;
    IconPath: string;
    OrderSequence: number;
}

export class MoodListOutputModel
{
    MoodId:number;
    MoodName:string;
    EmotionPath:string;
    Rating:number;
    OrderSequence:number;
}

export class QuickPostCreateUpdateInputModel {
    UserQuickPostId: number;
    UserDetailsId: number;
    QuickPostTypeId: number;
    MoodId: number;
    MoodRatingId: number;
    Title: string;
    DiaryDate: string;
    DiaryTimeFrom: string;
    DiaryTimeTo:string;
    PostContent: string;
    Location: string;
    TagCategory: string;
    Latitude: number;
    Longitude: number;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}
export class TblUserQuickPosts {
    UserQuickPostId: number;
    UserDetailsId: number;
    QuickPostTypeId: number;
    MoodId: number;
    MoodRatingId: number;
    Title: string;
    DiaryDate: string;
    DiaryTimeFrom: string;
    DiaryTimeTo: string;
    PostContent: string;
    Location: string;
    TagCategory: string;
    Latitude: number;
    Longitude: number;
    CreatedDate: string;
    CreatedTime: string;
    CreatedBy: number;
    ChangedDate: string;
    ChangedTime: string;
    ChangedBy: number;
}

export class QuickPostLoadDataOutputModel {
    UserQuickPost: TblUserQuickPosts;
    QuickPostTypeList: Array<QuickPostTypeListOutputModel>;
    MoodList: Array<MoodListOutputModel>;
    MoodRatingList: Array<MoodRatingListOutputModel>;
    QuickPostDocumentList: Array<QuickPostDocumentListOutputModel>;
}

export class QuickPostDocumentListOutputModel {
    UserQuickPostDocumentId: number;
    UserDetailsId: number;
    UserQuickPostId: number;
    DocumentType: string;
    Size: string;
    FileType: string;
    DocumentName: string;
    DocumentOldName: string;
    DocumentPath: string;
    CreatedDate: Date;
    CreatedTime: Date;
    CreatedBy: number;
    ChangedDate: Date;
    ChangedTime: Date;
    ChangedBy: number;
}

export class GetUserQuickPostsInputModel
{
    UserDetailsId:number;
    ShowAllYN?:boolean;
}

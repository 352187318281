<div class="form-group" [class.no-margin]="!applyMargin">
  <div class="d-flex justify-content-between">
    <label class="form-label" [attr.for]="for_lbl">{{label}} <span *ngIf="showInfoIcon" ngbTooltip="{{tooltipInfo}}" placement="{{position}}">
   <span data-feather="help-circle"
    class="info-icon align-middle mr-sm-25 mr-0"></span></span> <strong *ngIf="showRequiredSign">*</strong></label>
  </div>
  <div class="input-group input-group-merge form-password-toggle">
    <input #textBox [type]="type=='password' ? passwordTextType ? 'text' : 'password' : 'text'" class="form-control"
      [ngClass]="classes" [placeholder]="placeholder" [ngModel]="value" [attr.tabindex]="tabindex" 
      [attr.readonly]="readonly" [attr.autocomplete]="autocomplete" [attr.aria_describedby]="aria_describedby"
      (ngModelChange)="OnChange($event)" (focusout)="emitFocusOutEvent($event)" />
    <div *ngIf="type=='password' && showEyeIcon" class="input-group-append">
      <span class="input-group-text cursor-pointer" (click)="togglePasswordTextType()"><i class="feather font-small-4" [ngClass]="{
                  'icon-eye': passwordTextType,
                  'icon-eye-off': !passwordTextType
                }"></i></span>
    </div>
  </div>
</div>
<!-- [attr.tabindex]="tabindex" -->
<!-- <h1>Blog Component</h1> -->
<div class="content-body">
  <div id="user-profile">
    <!-- profile header -->
    <div class="row">
      <div class="col-12">
        <div class="card profile-header mb-2">
          <!-- profile cover photo -->
          <div class="cover-image-container">
            <img class="card-img-top cover-image" src="{{ currentUser?.CoverImagePath }}" alt="User Profile Image" />
            <div class="icon-container cover-icon" style="color: #fff;">
              <input type="file" class="custom-file-input" (change)="onCoverImageUpload($event)" />
              <i [data-feather]="'camera'"></i>
            </div>
            <ng-template #coverContent *ngIf="cropuploader2" let-modal>
              <div class="modal-header">
                <h3 class="modal-title m-0" style="color:#52057f">{{'BLOGS.LABEL_COVER_PICTURE'|translate}}</h3>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <image-cropper *ngIf="cropuploader2" [imageChangedEvent]="imageCroppedEvent"
                  [maintainAspectRatio]="true" [aspectRatio]="16/9" [resizeToWidth]="1920" format="png"
                  (imageCropped)="onImageCropped($event)"></image-cropper>
              </div>
              <div class="modal-footer">
                <button type="button" autofocus class="btn btn-primary" *ngIf="cropuploader2"
                  (click)="cropUpload('coverpicture')" rippleEffect>
                  {{'BLOGS.LABEL_CROP'|translate}}
                </button>
                <button type="button" class="btn btn-danger" *ngIf="cropuploader2" (click)="onProfilePhotoReset('cover')"
                  rippleEffect>
                  {{'BLOGS.LABEL_RESET'|translate}}
                </button>
              </div>

            </ng-template>
          </div>
          <!--/ profile cover photo -->

          <div class="position-relative">
            <!-- profile picture -->
            <div class="profile-img-container d-flex align-items-center">
              <div class="profile-img position-relative">
                <img src="{{ currentUser?.ProfileImagePath }}" (click)="supportFunction()" class="rounded img-fluid"
                  alt="Card image" />
                <div class="icon-container profile-icon" style="color: #fff;">
                  <input type="file" class="custom-file-input" (change)="onImageUpload($event)" />
                  <i [data-feather]="'camera'"></i>
                </div>
              </div>
              <!-- profile title -->
              <div class="profile-title ml-3">
                <h2 class="text-white">{{ currentUser?.FullName }}</h2>
              </div>
            </div>

            <ng-template #uploaderContent *ngIf="cropuploader" let-modal>
              <div class="modal-header">
                <h3 class="modal-title m-0" style="color:#52057f">{{'BLOGS.LABEL_YOUR_PROFILE_PICTURE'|translate}}</h3>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <image-cropper *ngIf="cropuploader" [imageChangedEvent]="imageCroppedEvent" [maintainAspectRatio]="true"
                  [aspectRatio]="1/1" [resizeToWidth]="200" format="png"
                  (imageCropped)="onImageCropped($event)"></image-cropper>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" *ngIf="cropuploader"
                  (click)="cropUpload('profilepicture')" rippleEffect>
                  {{'BLOGS.LABEL_CROP'|translate}}
                </button>
                <button type="button" class="btn btn-danger" *ngIf="cropuploader" (click)="onProfilePhotoReset('profile')"
                  rippleEffect>
                  {{'BLOGS.LABEL_RESET'|translate}}
                </button>
              </div>

            </ng-template>
          </div>
          <div class="profile-header-nav">

            <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
              <button class="btn btn-icon navbar-toggler" type="button" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                (click)="toggleMenu = !toggleMenu" rippleEffect>
                <i data-feather="align-justify" class="font-medium-5"></i>
              </button>
              <!-- collapse  -->
              <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="toggleMenu">
                <div class="profile-tabs d-flex justify-content-between flex-wrap mt-1 mt-md-0">
                  <ul class="nav nav-pills mb-0">
                    <ng-container *ngFor="let tab of tabs">
                      <li class="nav-item">
                        <a class="nav-link font-weight-bold" [class.active]="tab.id === active"
                          (click)="active = tab.id;onMainTabActive(tab.id,tab.searchParameter)">
                          <span class="d-none d-md-block">{{ tab.title }}</span>
                          <i *ngIf="tab.icon" [data-feather]="tab.icon" class="d-block d-md-none"></i>
                        </a>
                      </li>
                    </ng-container>
                  </ul>
                  <!-- edit button -->
                  <!-- <button class="btn btn-primary" rippleEffect>
                    <i data-feather="edit" class="d-block d-md-none"></i>
                    <span class="font-weight-bold d-none d-md-block">Edit</span>
                  </button> -->
                </div>
              </div>
              <!--/ collapse  -->
            </nav>
            <!--/ navbar -->
          </div>
        </div>
      </div>
    </div>
    <div class="tab-content">
      <div id="diary" class="tab-pane" [class.active]="active === 'diary'">
        <div class="row align-items-end mx-0">
          <div class="col-md-6 col-lg-3">
            <div class="d-flex align-items-center">
              <label class="mr-2">{{'BLOGS.LABEL_DATE_RANGE'|translate}}:</label>
              <ng-select class="flex-grow-1" placeholder="Select Date Range" [items]="dayFilterOptions"
                bindLabel="label" (ngModelChange)="onDateRangeChange($event)"
                [(ngModel)]="selectedDateRange"></ng-select>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="d-flex align-items-center">
              <label class="mr-2">{{'BLOGS.LABEL_MONTH'|translate}}:</label>
              <ng-select class="flex-grow-1" placeholder="Select Month" [items]="monthList"
                (ngModelChange)="onMonthChange($event)" [(ngModel)]="selectedMonth"></ng-select>
            </div>
          </div>
          <div class="col-md-6 col-lg-2">
            <!-- First datepicker with label -->
            <div class="d-flex align-items-center">
              <label for="datepicker1" class="mr-2" style="text-wrap: nowrap;">{{'BLOGS.LABEL_FROM_DATE'|translate}}:</label>
              <input type="text" class="form-control" style="height:38px" id="datepicker1" bsDatepicker placeholder="Select date"
                [bsConfig]="{ dateInputFormat:dateformat2 ,containerClass:'theme-blue' }" (ngModelChange)="validateDates('fromDate',$event)"
                [(ngModel)]="fromDate">
            </div>
          </div>
          <div class="col-md-6 col-lg-2">
            <!-- Second datepicker with label -->
            <div class="d-flex align-items-center">
              <label for="datepicker2" class="mr-2" style="text-wrap: nowrap;">{{'BLOGS.LABEL_TO_DATE'|translate}}:</label>
              <input type="text" class="form-control" style="height:38px" id="datepicker2" bsDatepicker placeholder="Select date"
                [bsConfig]="{ dateInputFormat: dateformat2 ,containerClass:'theme-blue'}" (ngModelChange)="validateDates('toDate',$event)"
                [(ngModel)]="toDate">
            </div>
          </div>
          <div class="col-md-12 col-lg-2">
            <!-- Search button -->
            <button class="btn btn-primary btn-block" style="height: 38px;" (click)="onSearch()">{{'BLOGS.LABEL_SEARCH'|translate}}</button>
          </div>
        </div>
        <div>
          <!-- First Card -->
          <div class="row pt-2">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <swiper class="swiper-fade-effect swiper-container" style="scale: 0;" [config]="swiperFadeEffect"
                *ngIf="onThisday&&(onThisday.length>0)">
                <div *ngFor="let onThisdays of onThisday; let q = index">
                  <div class="card-header  card-home d-flex justify-content-between align-items-center pb-0"
                    style="padding-top:12px; height:52px;  border-radius:8px 8px 0 0">
                    <div class="m-0" style="line-height: normal;">
                      <h4 class="m-0" style="color:#fff; line-height: normal; font-family: karla;">On This Day
                        <b>{{onThisday[q]?.DiaryDate | date : 'MMMM dd,yyyy'}}</b>
                      </h4>
                      <h2 class="m-0" style="color:#fff; line-height: normal;font-family: karla;">{{
                        getYearsAgo(onThisday[q]?.DiaryDate) }} Years Ago</h2>
                    </div>
                    <!-- <div style="margin-bottom: 15px; color:#fff" ngbTooltip="{{'HOME.TOOLTIP_BUTTON_TEXT_REFRESH'| translate}}">
                   <i data-feather="more-vertical" class="font-medium-3 cursor-pointer"></i>
               </div> -->
                  </div>
                  <div class="card card-home">
                    <ngb-carousel [interval]="3000" *ngIf="onThisday[q]?.QuickPostDocumentList!=0">
                      <ng-template ngbSlide *ngFor="let item of onThisday[q]?.QuickPostDocumentList; let i = index">
                        <div class="carousel-img-wrapper">
                          <div
                            style="height:255px; background-image: url('{{onThisday[q]?.QuickPostDocumentList[i]?.DocumentPath}}');background-repeat: no-repeat; background-size: cover; background-position: center;filter: blur(8px);">
                            &nbsp; &nbsp;
                          </div>
                          <img
                            style="width: auto;position: absolute; padding: 3% 0 3% 0; top: 50%;left: 50%;height: 100%;transform: translate(-50%, -50%) scale(1);"
                            src="{{onThisday[q]?.QuickPostDocumentList[i]?.DocumentPath}}">
                        </div>
                      </ng-template>
                    </ngb-carousel>
                    <div *ngIf="!onThisday[q]?.QuickPostDocumentList[0]"
                      style="height:255px; background-image: url('assets/images/pages/Asset 84.png');background-repeat: no-repeat; background-size: cover; background-position: center;">
                      &nbsp; &nbsp;
                    </div>
                    <div class="card-body" style="height:210px;">
                      <h4 class="card-title" style="color:rgba(50, 48, 145, 1); margin-bottom: 8px;">
                        {{onThisday[q]?.Title|truncate:50}}</h4>
                      <div>
                        <div class="avatar">
                          <img src="{{ currentUser?. ProfileImagePath}}" width="34" height="34" alt="Avatar" />
                        </div>
                        &nbsp; by <strong> {{ currentUser?.FullName}} </strong>{{onThisday[q]?.DiaryDate | date : 'MMMM dd,yyyy'}} <br> {{btnname}}
                        Time From: <strong>{{onThisday[q]?.DiaryTimeFrom | date:'HH:mm'}}</strong>
                        {{btnname}} Time To: <b>{{onThisday[q]?.DiaryTimeTo | date:'HH:mm'}}</b>
                        &nbsp; <i *ngIf="onThisday[q]?.Location" class="change-color" data-feather="map-pin"></i>
                        {{onThisday[q]?.Location}}<br>
                      </div>
                      <div
                        *ngFor="let id of onThisday[q]?.TagCategory | jsonArrayFilter:'tag' let i = index;let isLast = last"
                        class="badge badge-light-primary profile-badge card-home"
                        style="font-size:small; font-family: lato; margin: 5px 5px;">
                        <span *ngIf="i <= 4" [ngbTooltip]="id">{{ id | truncate: 10 }}</span>
                        <span *ngIf="i > 4 && isLast">+{{ i - 4 }} more</span>
                      </div>
                      <!-- <hr class="solid1"> -->
                      <div class="card-text wrap normal-line-height" style="margin:0;" appRemoveMargins
                        [innerHTML]="onThisday[q]?.PostContent | truncate1: 300" class="no-margin">
                      </div>
                    </div>
                    <div class="card-footer" style="text-align: right;">
                      <a class="text-muted" (click)="EditQuickPost(onThisday[q]?.UserQuickPostId)">Read More</a>
                    </div>
                  </div>
                </div>
              </swiper>

              <ng-template [ngIf]="onThisday&&(onThisday.length==0)">
                <div class="card">
                  <app-skeleton-loader [width]="200" [showimage]="true" [showcircle]="false" [height]="31"
                    [linenumber]="8"></app-skeleton-loader>
                </div>
              </ng-template>
            </div>

            <!-- Loop for other cards -->
            <ng-container  *ngFor="let item of latestQuickPost; let j = index">
              <div class="col-lg-6 col-md-6 col-sm-12 quick-post-item">
                <div class="card card-home itemrow" style="margin-bottom: 10px;">
                  <ngb-carousel [interval]="3000" *ngIf="latestQuickPost[j]?.QuickPostDocumentList.length > 0">
                    <ng-template ngbSlide
                      *ngFor="let image of imagetag(latestQuickPost[j]?.QuickPostDocumentList); let i = index">
                      <div class="carousel-img-wrapper">
                        <div
                          style="height: 308px; background-image: url('{{image}}'); background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 8px;filter: blur(8px);">

                        </div>
                        <img
                          style="width: auto;position: absolute; padding: 3% 0 3% 0; top: 50%;left: 50%;height: 100%;transform: translate(-50%, -50%) scale(1);"
                          src="{{image}}">
                      </div>
                    </ng-template>
                  </ngb-carousel>
                  <div *ngIf="latestQuickPost[j]?.QuickPostDocumentList.length == 0"
                    style="height: 308px; background-image: url('assets/images/pages/Asset 84.png'); background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 8px 8px 0 0;">
                  </div>
                  <div class="card-body" style="min-height:210px;">
                    <h4 class="card-title" style="color:rgba(50, 48, 145, 1); margin-bottom: 8px;">
                      {{latestQuickPost[j]?.Title|truncate:50}}</h4>
                    <div>
                      <b style="color: #52057f;">{{latestQuickPost[j]?.DiaryDate | date : 'MMMM dd , yyyy'}}</b>
                      {{btnname}}
                      Time From: <strong>{{latestQuickPost[j]?.DiaryTimeFrom | date:'h:mm a'}}</strong>
                      {{btnname}} Time To: <b>{{latestQuickPost[j]?.DiaryTimeTo | date:'h:mm a'}}</b>
                      <br> <i *ngIf="latestQuickPost[j]?.Location" data-feather="map-pin"></i>
                      {{latestQuickPost[j]?.Location}}
                    </div>
                    <div *ngFor="let id of parseTags(latestQuickPost[j]?.TagCategory) let i = index;let isLast = last"
                      class="badge badge-light-primary profile-badge card-home"
                      style="font-size:small; font-family: lato; margin: 5px 5px;">
                      <span *ngIf="i <= 4" [ngbTooltip]="id.name">{{ id.name | truncate: 10 }}</span>
                      <span *ngIf="i > 4 && isLast">+{{ i % 4 }} more</span>
                    </div>
                    <div class="card-text wrap m-0 no-spacing" style="margin:0" appRemoveMargins
                      [innerHTML]="latestQuickPost[j]?.PostContent | truncate: 320">
                    </div>
                  </div>
                  <div class=" card-footers" style="text-align: right;">
                    <a class="text-muted" (click)="EditQuickPost(latestQuickPost[j]?.UserQuickPostId)">Read More</a>
                  </div>
                </div>
              </div>

            </ng-container>
            <ng-template *ngIf="!latestQuickPost">
              <div class="card col-lg-6 col-md-6 col-sm-12">
                <app-skeleton-loader [width]="200" [showimage]="true" [showcircle]="false" [height]="30"
                  [linenumber]="7"></app-skeleton-loader>
              </div>
            </ng-template>
          </div>
        </div>

      </div>

      <!-------------------------------------------------------------Diary Entries End------------------------------------------------------------------------------>

      <div id="shared" class="tab-pane" [class.active]="active === 'shared'">
        <div class="row align-items-end mx-0">
          <div class="col-md-6 col-lg-3">
            <div class="d-flex align-items-center">
              <label class="mr-2">{{'BLOGS.LABEL_DATE_RANGE'|translate}}:</label>
              <ng-select class="flex-grow-1" placeholder="Select Date Range" [items]="dayFilterOptions"
                bindLabel="label" (ngModelChange)="onDateRangeChange($event)"
                [(ngModel)]="selectedDateRange"></ng-select>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="d-flex align-items-center">
              <label class="mr-2">{{'BLOGS.LABEL_MONTH'|translate}}:</label>
              <ng-select class="flex-grow-1" placeholder="Select Month" [items]="monthList"
                (ngModelChange)="onMonthChange($event)" [(ngModel)]="selectedMonth"></ng-select>
            </div>
          </div>
          <div class="col-md-6 col-lg-2">
            <!-- First datepicker with label -->
            <div class="d-flex align-items-center">
              <label for="datepicker1" class="mr-2" style="text-wrap: nowrap;">{{'BLOGS.LABEL_FROM_DATE'|translate}}:</label>
              <input type="text" class="form-control" style="height: 38px;" id="datepicker1" bsDatepicker placeholder="Select date"
                [bsConfig]="{ dateInputFormat: dateformat2 ,containerClass:'theme-blue' }" (ngModelChange)="validateDates('fromDate',$event)"
                [(ngModel)]="fromDate">
            </div>
          </div>
          <div class="col-md-6 col-lg-2">
            <!-- Second datepicker with label -->
            <div class="d-flex align-items-center">
              <label for="datepicker2" class="mr-2" style="text-wrap: nowrap;">{{'BLOGS.LABEL_TO_DATE'|translate}}:</label>
              <input type="text" class="form-control" style="height: 38px;" id="datepicker2" bsDatepicker placeholder="Select date"
                [bsConfig]="{ dateInputFormat: dateformat2 ,containerClass:'theme-blue' }" (ngModelChange)="validateDates('toDate',$event)"
                [(ngModel)]="toDate">
            </div>
          </div>
          <div class="col-md-12 col-lg-2">
            <!-- Search button -->
            <button class="btn btn-primary btn-block" style="height: 38px;" (click)="onSearch()">{{'BLOGS.LABEL_SEARCH'|translate}}</button>
          </div>
        </div>
        <div>
          <!-- First Card -->
          <div class="row pt-2">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <swiper class="swiper-fade-effect swiper-container" style="scale: 0;" [config]="swiperFadeEffect"
                *ngIf="onThisday&&(onThisday.length>0)">
                <div *ngFor="let onThisdays of onThisday; let q = index">
                  <div class="card-header  card-home d-flex justify-content-between align-items-center pb-0"
                    style="padding-top:12px; height:52px;  border-radius:8px 8px 0 0">
                    <div class="m-0" style="line-height: normal;">
                      <h4 class="m-0" style="color:#fff; line-height: normal; font-family: karla;">On This Day
                        <b>{{onThisday[q]?.DiaryDate | date : 'MMMM dd,yyyy'}}</b>
                      </h4>
                      <h2 class="m-0" style="color:#fff; line-height: normal;font-family: karla;">{{
                        getYearsAgo(onThisday[q]?.DiaryDate) }} Years Ago</h2>
                    </div>
                    <!-- <div style="margin-bottom: 15px; color:#fff" ngbTooltip="{{'HOME.TOOLTIP_BUTTON_TEXT_REFRESH'| translate}}">
                   <i data-feather="more-vertical" class="font-medium-3 cursor-pointer"></i>
               </div> -->
                  </div>
                  <div class="card card-home">
                    <ngb-carousel [interval]="3000" *ngIf="onThisday[q]?.QuickPostDocumentList!=0">
                      <ng-template ngbSlide *ngFor="let item of onThisday[q]?.QuickPostDocumentList; let i = index">
                        <div class="carousel-img-wrapper">
                          <div
                            style="height:255px; background-image: url('{{onThisday[q]?.QuickPostDocumentList[i]?.DocumentPath}}');background-repeat: no-repeat; background-size: cover; background-position: center;filter: blur(8px);">
                            &nbsp; &nbsp;
                          </div>
                          <img
                            style="width: auto;position: absolute; padding: 3% 0 3% 0; top: 50%;left: 50%;height: 100%;transform: translate(-50%, -50%) scale(1);"
                            src="{{onThisday[q]?.QuickPostDocumentList[i]?.DocumentPath}}">
                        </div>
                      </ng-template>
                    </ngb-carousel>
                    <div *ngIf="!onThisday[q]?.QuickPostDocumentList[0]"
                      style="height:255px; background-image: url('assets/images/pages/Asset 84.png');background-repeat: no-repeat; background-size: cover; background-position: center;">
                      &nbsp; &nbsp;
                    </div>
                    <div class="card-body" style="height:210px;">
                      <h4 class="card-title" style="color:rgba(50, 48, 145, 1); margin-bottom: 8px;">
                        {{onThisday[q]?.Title|truncate:50}}</h4>
                      <div>
                        <div class="avatar">
                          <img src="{{ currentUser?. ProfileImagePath}}" width="34" height="34" alt="Avatar" />
                        </div>
                        &nbsp; by <strong> {{ currentUser?.FullName}} </strong>{{onThisday[q]?.DiaryDate | date : 'MMMM dd,yyyy'}} <br> {{btnname}}
                        Time From: <strong>{{onThisday[q]?.DiaryTimeFrom | date:'HH:mm'}}</strong>
                        {{btnname}} Time To: <b>{{onThisday[q]?.DiaryTimeTo | date:'HH:mm'}}</b>
                        &nbsp; <i *ngIf="onThisday[q]?.Location" class="change-color" data-feather="map-pin"></i>
                        {{onThisday[q]?.Location}}<br>
                      </div>
                      <div
                        *ngFor="let id of onThisday[q]?.TagCategory | jsonArrayFilter:'tag' let i = index;let isLast = last"
                        class="badge badge-light-primary profile-badge card-home"
                        style="font-size:small; font-family: lato; margin: 5px 5px;">
                        <span *ngIf="i <= 4" [ngbTooltip]="id">{{ id | truncate: 10 }}</span>
                        <span *ngIf="i > 4 && isLast">+{{ i - 4 }} more</span>
                      </div>
                      <!-- <hr class="solid1"> -->
                      <div class="card-text wrap normal-line-height" style="margin:0;" appRemoveMargins
                        [innerHTML]="onThisday[q]?.PostContent | truncate1: 300" class="no-margin">
                      </div>
                    </div>
                    <div class="card-footer" style="text-align: right;">
                      <a class="text-muted" (click)="EditQuickPost(onThisday[q]?.UserQuickPostId)">Read More</a>
                    </div>
                  </div>
                </div>
              </swiper>

              <ng-template [ngIf]="onThisday&&(onThisday.length==0)">
                <div class="card">
                  <app-skeleton-loader [width]="200" [showimage]="true" [showcircle]="false" [height]="31"
                    [linenumber]="8"></app-skeleton-loader>
                </div>
              </ng-template>
            </div>

            <!-- Loop for other cards -->
            <ng-container *ngFor="let item of latestQuickPost; let j = index">
              <div class="col-lg-6 col-md-6 col-sm-12 quick-post-item">
                <div class="card card-home itemrow" style="margin-bottom: 10px;">
                  <ngb-carousel [interval]="3000" *ngIf="latestQuickPost[j]?.QuickPostDocumentList.length > 0">
                    <ng-template ngbSlide
                      *ngFor="let image of imagetag(latestQuickPost[j]?.QuickPostDocumentList); let i = index">
                      <div class="carousel-img-wrapper">
                        <div
                          style="height: 308px; background-image: url('{{image}}'); background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 8px;filter: blur(8px);">

                        </div>
                        <img
                          style="width: auto;position: absolute; padding: 3% 0 3% 0; top: 50%;left: 50%;height: 100%;transform: translate(-50%, -50%) scale(1);"
                          src="{{image}}">
                      </div>
                    </ng-template>
                  </ngb-carousel>
                  <div *ngIf="latestQuickPost[j]?.QuickPostDocumentList.length == 0"
                    style="height: 308px; background-image: url('assets/images/pages/Asset 84.png'); background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 8px 8px 0 0;">
                  </div>
                  <div class="card-body" style="min-height:210px;">
                    <h4 class="card-title" style="color:rgba(50, 48, 145, 1); margin-bottom: 8px;">
                      {{latestQuickPost[j]?.Title|truncate:50}}</h4>
                    <div>
                      <div class="avatar">
                        <img src="{{ currentUser?. ProfileImagePath}}" width="34" height="34" alt="Avatar" />
                      </div>
                      &nbsp; by <strong style="cursor: pointer;" (click)="openUploaderModal1(uploaderContent12)"> {{
                        currentUser?.FullName}} </strong>{{latestQuickPost[j]?.DiaryDate | date : 'MMMM dd,yyyy'}} <br>
                      {{btnname}}
                      Time From: <strong>{{latestQuickPost[j]?.DiaryTimeFrom | date:'HH:mm'}}</strong>
                      {{btnname}} Time To: <b>{{latestQuickPost[j]?.DiaryTimeTo | date:'HH:mm'}}</b>
                      &nbsp; <i *ngIf="latestQuickPost[j]?.Location" class="change-color" data-feather="map-pin"></i>
                      {{latestQuickPost[j]?.Location}}<br>
                    </div>
                    <div *ngFor="let id of parseTags(latestQuickPost[j]?.TagCategory) let i = index;let isLast = last"
                      class="badge badge-light-primary profile-badge card-home"
                      style="font-size:small; font-family: lato; margin: 5px 5px;">
                      <span *ngIf="i <= 4" [ngbTooltip]="id.name">{{ id.name | truncate: 10 }}</span>
                      <span *ngIf="i > 4 && isLast">+{{ i % 4 }} more</span>
                    </div>
                    <div class="card-text wrap m-0 no-spacing" style="margin:0" appRemoveMargins
                      [innerHTML]="latestQuickPost[j]?.PostContent | truncate: 320">
                    </div>
                  </div>
                  <div class=" card-footers" style="text-align: right;">
                    <a class="text-muted" (click)="EditQuickPost(latestQuickPost[j]?.UserQuickPostId)">Read More</a>
                  </div>
                </div>
              </div>

            </ng-container>
          </div>
        </div>
      </div>



      <!-------------------------------------------------------------Shared End------------------------------------------------------------------------------>



      <div id="public" class="tab-pane" [class.active]="active === 'public'">
        <div class="row align-items-end mx-0">
          <div class="col-md-6 col-lg-3">
            <div class="d-flex align-items-center">
              <label class="mr-2">{{'BLOGS.LABEL_DATE_RANGE'|translate}}:</label>
              <ng-select class="flex-grow-1" placeholder="Select Date Range" [items]="dayFilterOptions"
                bindLabel="label" (ngModelChange)="onDateRangeChange($event)"
                [(ngModel)]="selectedDateRange"></ng-select>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="d-flex align-items-center">
              <label class="mr-2">{{'BLOGS.LABEL_MONTH'|translate}}:</label>
              <ng-select class="flex-grow-1" placeholder="Select Month" [items]="monthList"
                (ngModelChange)="onMonthChange($event)" [(ngModel)]="selectedMonth"></ng-select>
            </div>
          </div>
          <div class="col-md-6 col-lg-2">
            <!-- First datepicker with label -->
            <div class="d-flex align-items-center">
              <label for="datepicker1" class="mr-2" style="text-wrap: nowrap;">{{'BLOGS.LABEL_FROM_DATE'|translate}}:</label>
              <input type="text" class="form-control" style="height: 38px;" id="datepicker1" bsDatepicker placeholder="Select date"
                [bsConfig]="{ dateInputFormat: dateformat2 ,containerClass:'theme-blue' }" (ngModelChange)="validateDates('fromDate',$event)"
                [(ngModel)]="fromDate">
            </div>
          </div>
          <div class="col-md-6 col-lg-2">
            <!-- Second datepicker with label -->
            <div class="d-flex align-items-center">
              <label for="datepicker2" class="mr-2" style="text-wrap: nowrap;">{{'BLOGS.LABEL_TO_DATE'|translate}}:</label>
              <input type="text" class="form-control" style="height: 38px;" id="datepicker2" bsDatepicker placeholder="Select date"
                [bsConfig]="{ dateInputFormat: dateformat2 , containerClass:'theme-blue' }" (ngModelChange)="validateDates('toDate',$event)"
                [(ngModel)]="toDate">
            </div>
          </div>
          <div class="col-md-12 col-lg-2">
            <!-- Search button -->
            <button class="btn btn-primary btn-block" style="height: 38px;" (click)="onSearch(4)">{{'BLOGS.LABEL_SEARCH'|translate}}</button>
          </div>
        </div>
        <div>
          <!-- First Card -->
          <div class="row mx-0 pt-2">
            <!-- <div class="col-lg-6 col-md-6 col-sm-12">
              <swiper class="swiper-fade-effect swiper-container" style="scale: 0;" [config]="swiperFadeEffect"
                *ngIf="onThisday&&(onThisday.length>0)">
                <div *ngFor="let onThisdays of onThisday; let q = index">
                  <div class="card-header  card-home d-flex justify-content-between align-items-center pb-0"
                    style="padding-top:12px; height:52px;  border-radius:8px 8px 0 0">
                    <div class="m-0" style="line-height: normal;">
                      <h4 class="m-0" style="color:#fff; line-height: normal; font-family: karla;">On This Day
                        <b>{{onThisday[q]?.DiaryDate | date : 'MMMM dd,yyyy'}}</b>
                      </h4>
                      <h2 class="m-0" style="color:#fff; line-height: normal;font-family: karla;">{{
                        getYearsAgo(onThisday[q]?.DiaryDate) }} Years Ago</h2>
                    </div>
                  </div>
                  <div class="card card-home">
                    <ngb-carousel [interval]="3000" *ngIf="onThisday[q]?.QuickPostDocumentList!=0">
                      <ng-template ngbSlide *ngFor="let item of onThisday[q]?.QuickPostDocumentList; let i = index">
                        <div class="carousel-img-wrapper">
                          <div
                            style="height:255px; background-image: url('{{onThisday[q]?.QuickPostDocumentList[i]?.DocumentPath}}');background-repeat: no-repeat; background-size: cover; background-position: center;filter: blur(8px);">
                            &nbsp; &nbsp;
                          </div>
                          <img
                            style="width: auto;position: absolute; padding: 3% 0 3% 0; top: 50%;left: 50%;height: 100%;transform: translate(-50%, -50%) scale(1);"
                            src="{{onThisday[q]?.QuickPostDocumentList[i]?.DocumentPath}}">
                        </div>
                      </ng-template>
                    </ngb-carousel>
                    <div *ngIf="!onThisday[q]?.QuickPostDocumentList[0]"
                      style="height:255px; background-image: url('assets/images/pages/Asset 84.png');background-repeat: no-repeat; background-size: cover; background-position: center;">
                      &nbsp; &nbsp;
                    </div>
                    <div class="card-body" style="height:210px;">
                      <h4 class="card-title" style="color:rgba(50, 48, 145, 1); margin-bottom: 8px;">
                        {{onThisday[q]?.Title|truncate:50}}</h4>
                      <div>
                        <div class="avatar">
                          <img src="{{ currentUser?. ProfileImagePath}}" width="34" height="34" alt="Avatar" />
                        </div>
                        &nbsp; by <strong> {{ currentUser?.FullName}} </strong>{{onThisday[q]?.DiaryDate | date : 'MMMM dd,yyyy'}} <br> {{btnname}}
                        Time From: <strong>{{onThisday[q]?.DiaryTimeFrom | date:'HH:mm'}}</strong>
                        {{btnname}} Time To: <b>{{onThisday[q]?.DiaryTimeTo | date:'HH:mm'}}</b>
                        &nbsp; <i *ngIf="onThisday[q]?.Location" class="change-color" data-feather="map-pin"></i>
                        {{onThisday[q]?.Location}}<br>
                      </div>
                      <div
                        *ngFor="let id of onThisday[q]?.TagCategory | jsonArrayFilter:'tag' let i = index;let isLast = last"
                        class="badge badge-light-primary profile-badge card-home"
                        style="font-size:small; font-family: lato; margin: 5px 5px;">
                        <span *ngIf="i <= 4" [ngbTooltip]="id">{{ id | truncate: 10 }}</span>
                        <span *ngIf="i > 4 && isLast">+{{ i - 4 }} more</span>
                      </div>
                      
                      <div class="card-text wrap normal-line-height" style="margin:0;" appRemoveMargins
                        [innerHTML]="onThisday[q]?.PostContent | truncate1: 300" class="no-margin">
                      </div>
                    </div>
                    <div class="card-footer" style="text-align: right;">
                      <a class="text-muted" (click)="EditQuickPost(onThisday[q]?.UserQuickPostId)">Read More</a>
                    </div>
                  </div>
                </div>
              </swiper>

              <ng-template [ngIf]="onThisday&&(onThisday.length==0)">
                <div class="card">
                  <app-skeleton-loader [width]="200" [showimage]="true" [showcircle]="false" [height]="31"
                    [linenumber]="8"></app-skeleton-loader>
                </div>
              </ng-template>
            </div> -->

            <!-- Loop for other cards -->
            <ng-container *ngIf="latestQuickPost.length==0"><img style="padding-left: 40%"
              src="assets/images/pages/noimageuploaded.png"></ng-container>
            <ng-container *ngFor="let item of latestQuickPost; let j = index">
              <div class="col-lg-6 col-md-6 col-sm-12 px-2 quick-post-item">
                <div class="card card-home itemrow" style="margin-bottom: 10px;">
                  <ngb-carousel [interval]="3000" *ngIf="latestQuickPost[j]?.QuickPostDocumentList.length > 0">
                    <ng-template ngbSlide
                      *ngFor="let image of imagetag(latestQuickPost[j]?.QuickPostDocumentList); let i = index">
                      <div class="carousel-img-wrapper">
                        <div
                          style="height: 308px; background-image: url('{{image}}'); background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 8px;filter: blur(8px);">

                        </div>
                        <img
                          style="width: auto;position: absolute; padding: 3% 0 3% 0; top: 50%;left: 50%;height: 100%;transform: translate(-50%, -50%) scale(1);"
                          src="{{image}}">
                      </div>
                    </ng-template>
                  </ngb-carousel>
                  <div *ngIf="latestQuickPost[j]?.QuickPostDocumentList.length == 0"
                    style="height: 308px; background-image: url('assets/images/pages/Asset 84.png'); background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 8px 8px 0 0;">
                  </div>
                  <div class="card-body" style="min-height:210px;">
                    <h4 class="card-title" style="color:rgba(50, 48, 145, 1); margin-bottom: 8px;">
                      {{latestQuickPost[j]?.Title|truncate:50}}</h4>
                    <div>
                      <div class="avatar">
                        <img src="{{ currentUser?. ProfileImagePath}}" width="34" height="34" alt="Avatar" />
                      </div>
                      &nbsp; by <strong style="cursor: pointer;" (click)="openUploaderModal1(uploaderContent12)"> {{
                        currentUser?.FullName}} </strong>{{latestQuickPost[j]?.DiaryDate | date :
                      'MMMM dd,yyyy'}} <br> {{btnname}}
                      Time From: <strong>{{latestQuickPost[j]?.DiaryTimeFrom | date:'HH:mm'}}</strong>
                      {{btnname}} Time To: <b>{{latestQuickPost[j]?.DiaryTimeTo | date:'HH:mm'}}</b>
                      &nbsp; <i *ngIf="latestQuickPost[j]?.Location" class="change-color" data-feather="map-pin"></i>
                      {{latestQuickPost[j]?.Location}}<br>
                    </div>
                    <div *ngFor="let id of parseTags(latestQuickPost[j]?.TagCategory) let i = index;let isLast = last"
                      class="badge badge-light-primary profile-badge card-home"
                      style="font-size:small; font-family: lato; margin: 5px 5px;">
                      <span *ngIf="i <= 4" [ngbTooltip]="id.name">{{ id.name | truncate: 10 }}</span>
                      <span *ngIf="i > 4 && isLast">+{{ i % 4 }} more</span>
                    </div>
                    <div class="card-text wrap m-0 no-spacing" style="margin:0" appRemoveMargins
                      [innerHTML]="latestQuickPost[j]?.PostContent | truncate: 320">
                    </div>
                  </div>
                  <div class=" card-footers" style="text-align: right;">
                    <a class="text-muted" (click)="EditQuickPost(latestQuickPost[j]?.UserQuickPostId)">Read More</a>
                  </div>
                </div>
              </div>

            </ng-container>
            <!-- <ng-template #uploaderContent12 let-modal>
              <div class="modal-header">
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h3>About {{currentUser?.FullName}}</h3>
                <p>Hello, my name is (Pushpraj) and I am an introvert. I prefer to listen more than talk. I enjoy
                  spending time alone or with a few close friends.</p>
                <h3>Joined</h3>
                <p>November 15,2015</p>
                <h3>Lives:</h3>
                <p>Ratlam,(M.P.)</p>
                <h3>Email:</h3>
                <p>pushpraj@missionmps.com</p>
                <h3>Website:</h3>
                <p>app.noosom.com/pushpraj</p>
              </div>

            </ng-template> -->
          </div>
        </div>
      </div>



      <!-------------------------------------------------------------Public End------------------------------------------------------------------------------>



      <div  id="photos" class="tab-pane" [class.active]="active === 'photos'">
        <ul ngbNav #navJustified="ngbNav" class="nav-tabs nav-justified">
          <li ngbNavItem (click)="onTabActive('Images')">
            <a ngbNavLink>Images</a>
            <ng-template ngbNavContent>
              <div *ngIf="attachmentlistimage.length==0"><img style="padding-left: 40%"
                  src="assets/images/pages/noimageuploaded.png"></div>
              <div class=" scroll-area">
                <div id="animated-thumbnails-gallery">
                  <lightgallery #AddressGallery [settings]="settings" [onInit]="onInitGallery">
                    <a *ngFor="let item of attachmentlistimage"
                      [attr.data-video]="item.type === 'Videos' ? item.data : null"
                      [attr.href]="item.type === 'Images' ? item.src : null"
                      [data-poster]="item.type === 'Videos' ? item.thumb :null" className="gallery-item"
                      data-sub-html="<p>{{item.id}}</p>">
                      <img *ngIf="item.type=='Images'" class="img-responsive justify-gallery" src="{{item.src}}"
                        alt="{{item.id}}" />
                    </a>
                  </lightgallery>
                </div>
              </div>
              <!-- Load More Button for Images Tab -->
              <!-- <button class="btn btn-primary floatingloadmore-button" [routerLink]="['/my-image-gallery/list']" *ngIf="showLoadMoreButtonImages">Load More Images</button> -->
              <ngb-pagination #pagination  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="floatingloadmore-button " *ngIf="attachmentlistimage.length>0" [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Images')"  [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true"
                [boundaryLinks]="true">
                <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                    [class]="'font-weight-bolder'"></span>Prev
                </ng-template>
                <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                    [class]="'font-weight-bolder'"></span>
                </ng-template>
              </ngb-pagination>

            </ng-template>
          </li>
          <li ngbNavItem (click)="onTabActive('Videos')">
            <a ngbNavLink>Videos</a>
            <ng-template ngbNavContent>
              <div *ngIf="attachmentlistimage.length==0"><img style="padding-left: 40%"
                  src="assets/images/pages/noimageuploaded.png">
              </div>
              <div class=" scroll-area">
                <lightgallery [settings]="settings" [onInit]="onInitGallery">
                  <a *ngFor="let item of attachmentlistimage" [attr.data-video]="item.type === 'Videos' ? item.data : null"
                    [attr.data-video]="item.type === 'Videos' ? item.data : null"
                    [attr.href]="item.type === 'Images' ? item.src : null"
                    [data-poster]="item.type === 'Videos' ? item.thumb :null" className="gallery-item"
                    data-sub-html="<p>{{item.id}}</p>">
                    <img *ngIf="item.type === 'Videos'" [src]="item.thumb" class="img-responsive justify-gallery"
                      alt="{{item.id}}" />
                  </a>
                </lightgallery>
              </div>
              <!-- Load More Button for Videos Tab -->
              <ngb-pagination  cdkDragHandle class="floatingloadmore-button"  *ngIf="attachmentlistimage.length>0" [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Videos')"  [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true"
                [boundaryLinks]="true">
                <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                    [class]="'font-weight-bolder'"></span>Prev
                </ng-template>
                <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                    [class]="'font-weight-bolder'"></span>
                </ng-template>
              </ngb-pagination>
            </ng-template>
          </li>

        </ul>
        <div [ngbNavOutlet]="navJustified" class="mt-2"></div>
      </div>


      <!-------------------------------------------------------------Photos End------------------------------------------------------------------------------>





      <div id="aboutme" class="tab-pane" [class.active]="active === 'aboutme'">
        <div class="card content-body px-3">
          <form id="frmAboutMe" [formGroup]="frmAboutMe" >
            <div class="row pt-3">
              <div class="col-12 " style="display: inline-flex;">
                <label class="form-label">
                  <p class="form-label heading-font m-0">{{'BLOGS.LABEL_STAY_ANONYMOUS'|translate}}:</p>
                </label>
                <div class="row pl-4" style="padding-top: 4px;">
                  <div class="col-pl-2">
                    <div class="custom-control custom-radio custom-control-primary">
                      <input type="radio" id="yes" formControlName="StayAnonymousYn" name="PayAnnualFeeYn" [value]="true" class="custom-control-input" />
                      <label class="custom-control-label" for="yes">{{'BLOGS.LABEL_YES'|translate}}</label>
                    </div>
                  </div>
                  <div class="col">
                    <div class="custom-control custom-radio custom-control-primary">
                      <input type="radio" id="no" formControlName="StayAnonymousYn" name="PayAnnualFeeYn" [value]="false" class="custom-control-input" />
                      <label class="custom-control-label" for="no">{{'BLOGS.LABEL_NO'|translate}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm">
                <label for="basicTextarea" class="form-label heading-font" style="color: #323091;">{{'BLOGS.LABEL_ABOUT_ME'|translate}}</label>
                <fieldset class="form-group">
                  <textarea class="form-control" formControlName="AboutMeDescription" rows="5" placeholder="Type Here..."></textarea>
                </fieldset>
              </div>
            </div>
            <span class="row m-0">
              <span *ngIf="userBlogs?.JoinedDate" class="col-6 p-0">
                  <p class="form-label heading-font m-0">{{'BLOGS.LABEL_JOINED'|translate}}</p>
                  <p><i [data-feather]="'calendar'"></i> {{userBlogs?.JoinedDate |date:dateformat}}</p>
              </span>
              <span *ngIf="userBlogs?.EmailAddress" class="col-6 p-0">
                <p class="form-label heading-font m-0" >{{'BLOGS.LABEL_EMAIL_ADDRESS'|translate}}  <span><a> <img align="right" height="17px" width="17px"
                  ngbTooltip="Open in External Tab"
                  src="assets/images/icons/external-link.svg"
                  (click)="openURL(userBlogs?.EmailAddress)"> </a>
              <a> <img align="right" height="18px" width="18px" src="assets/images/icons/copy.svg"
                ngbTooltip="Copy"
                  (click)="copyText(userBlogs?.EmailAddress)"> </a> </span></p>
                <p><i [data-feather]="'mail'"></i> {{userBlogs?.EmailAddress}}</p>
            </span>
          </span>
          <span class="row m-0">
              <span *ngIf="userBlogs?.PersonalWebUrl" class="col-6 p-0">
                  <p class="form-label heading-font m-0">{{'BLOGS.LABEL_MY_PERSONAL_URL'|translate}}<span><a> <img align="right" class="mr-5" height="17px" width="17px"
                    src="assets/images/icons/external-link.svg"
                    ngbTooltip="Open in External Tab"
                    (click)="openURL(userBlogs?.PersonalWebUrl)"> </a>
                <a> <img align="right" height="18px" width="18px" src="assets/images/icons/copy.svg"
                  ngbTooltip="Copy"
                    (click)="copyText(userBlogs?.PersonalWebUrl)"> </a> </span></p>
                  <p><i [data-feather]="'globe'"></i> {{userBlogs?.PersonalWebUrl}}</p>
              </span>
              <span *ngIf="userBlogs?.CurrentUserAddress" class="col-6 p-0">
                <p class="form-label heading-font m-0">{{'BLOGS.LABEL_LIVES'|translate}}</p>
                <p><i [data-feather]="'map-pin'"></i> {{userBlogs?.CurrentUserAddress?.FullAddress}}</p>
            </span>
          </span>
          </form>
          <div class="py-3">
            <button type="submit" class="btn btn-primary mr-2" (click)="onSubmit()" placement="top" ngbTooltip='Save Changes'>Save
              Changes</button>

            <button type="button" class="btn btn-outline-primary mx-2" (click)="loadAboutMeData()" placement="top"
              ngbTooltip='Cancel'>Cancel</button>
          </div>
        </div>
      </div>





      <!-------------------------------------------------------------About-Me End------------------------------------------------------------------------------>



    </div>
  </div>
</div>
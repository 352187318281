import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { locale as english } from '../i18n/en';
import { locale as french } from '../i18n/fr';
import { locale as german } from '../i18n/de';
import { locale as portuguese } from '../i18n/pt';
import { locale as gujarati } from '../i18n/gj';
import { locale as hindi } from '../i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { AddressesModel, TblUserAddresses } from '../address-model.model';
import { E } from 'assets/extensions/extensions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapModalType } from 'app/enums';
import { MapAddressDataModel, MapAddressOutputModel } from './map-address-data-model.model';
import { Constant } from 'app/Classes/constant';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
@Component({
  selector: 'app-map-address',
  templateUrl: './map-address.component.html',
  styleUrls: ['./map-address.component.scss']
})
export class MapAddressComponent implements OnInit {
  @ViewChild('SearchOnMapTextBox', { read: ElementRef }) txtSearchOnMap: ElementRef;

  public loading = false;
  currentAppLanguage: any;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public error = '';
  public IsDraggable: boolean = false;
  public IsSaveButtonVisible: boolean = false;
  public IsSearchTextVisible: boolean = false;
  public IsSearchOnMap: boolean = false;
  public SearchStringForMap: string = '';
  public mapModalType: MapModalType = MapModalType.SearchString;
  public Lat: number;
  public Long: number;
  public dialogTitle: string;
  public frmMapComponent: FormGroup;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDefaultUI: true,
    zoom: 18,
    // disableDoubleClickZoom: true,
    // maxZoom: 15,
    // minZoom: 8,
  };
  markers: Array<google.maps.MarkerOptions> = [];
  userAddresses: Array<AddressesModel> = [];  //Using Same for School
  constructor(
    private dialogRef: MatDialogRef<MapAddressComponent>,
    private _coreTranslationService: CoreTranslationService,
    private _toastrService: CustomToastrService,
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: MapAddressDataModel
  ) {
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._unsubscribeAll = new Subject();
    if (this.data) {
      // console.log('mapModalType');
      // console.log(this.data.mapModalType);
    //  console.log(this.data.Zoom);
      this.options.zoom = this.data.Zoom;
      this.mapModalType = this.data.mapModalType;
      if (typeof this.data.Data === 'string' && this.mapModalType == MapModalType.SearchString)  //Search On Map
        this.SearchStringForMap = E.ConvertToString(this.data.Data);
      else if (this.mapModalType == MapModalType.AddressModel || this.mapModalType == MapModalType.SchoolModel || this.mapModalType == MapModalType.QuickPostModel) {
        this.userAddresses = this.data.Data;
        if (this.userAddresses && this.userAddresses.length > 1)
          this.dialogTitle = "View all Addresses on a Map";
      }
      else if (this.mapModalType == MapModalType.LatLong) {
        let _date = this.data.Data as MapAddressOutputModel;
        this.Lat = _date.lat;
        this.Long = _date.long;
        this.SearchStringForMap = _date.searchstring;
      }
    }
  }
  
  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.txtSearchOnMap)
        this.txtSearchOnMap.nativeElement.focus();

    }, 500);
  }

  ngOnInit(): void {
    this.markers = [];
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.frmMapComponent = this._formBuilder.group({
      SearchField: [this.SearchStringForMap, Validators.required],
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    if(this.data.mapModalType == MapModalType.QuickPostModel){
    this.dialogTitle = this.currentAppLanguage.data.MAP.DIALOG_TITLE_VIEW_ADDRESS_ON_MAP;}
    if(this.data.mapModalType == MapModalType.AddressModel){
    this.dialogTitle = this.currentAppLanguage.data.MAP.DIALOG_TITLE_VIEW_ON_MAP;}
    if(this.data.mapModalType == MapModalType.SchoolModel){
    this.dialogTitle = this.currentAppLanguage.data.MAP.DIALOG_TITLE_VIEW_SCHOOL_ON_MAP;}
    if (this.IsSearchOnMap){
      this.dialogTitle = this.currentAppLanguage.data.MAP.DIALOG_TITLE_SEARCH_ON_MAP;}
    if (this.userAddresses && this.userAddresses.length > 0) {
      // console.log('this.userAddresses');
      // console.log(this.userAddresses);
      setTimeout(() => {
        this.userAddresses.forEach(element => {
          if (element.Latitude != null && element.Longitude != null) {
            this.center = {
              lat: element.Latitude,
              lng: element.Longitude,
            };
            this.markers.push(
              {
                position: { lat: element.Latitude, lng: element.Longitude },
                draggable: this.IsDraggable,
                // label: {
                //   color: 'red',
                //   text: element.AddressName,
                // },
                title: (element.AddressName + ': ' + element.Address1 + ', ' + element.AddressTown + ', ' + element.AddressPostCode),
                //options: { animation: google.maps.Animation.BOUNCE },
              });
            // console.log('this.markers');
            // console.log(this.markers);
          }
        });
      }, 10);
    }
    if (E.ConvertToString(this.SearchStringForMap) != '') {
      if (this.mapModalType == MapModalType.SearchString) {
        this.getGeoLocation(this.SearchStringForMap).subscribe(data => {
          //console.log('GeoLocation called');
          //console.log(data);
          this.Lat = (<google.maps.LatLng>data).lat();
          this.Long = (<google.maps.LatLng>data).lng();
          // console.log(this.Lat);
          // console.log(this.Long);
          this.addMarkerOnMap(this.Lat, this.Long, this.SearchStringForMap);
        });
      }
      else if (this.mapModalType == MapModalType.LatLong) {
        this.addMarkerOnMap(this.Lat, this.Long, this.SearchStringForMap);
      }
    }
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCancelClick();
      }
    });
  }
  onSearchClick() {

    if (E.ConvertToString(this.frmMapComponent.get('SearchField').value) != '') {
      this.getGeoLocation(this.frmMapComponent.get('SearchField').value).subscribe(data => {
    
        this.Lat = (<google.maps.LatLng>data).lat();
        this.Long = (<google.maps.LatLng>data).lng();
        this.addMarkerOnMap(this.Lat, this.Long, this.SearchStringForMap);
      });
    }
  }

  addMarkerOnMap(Latitude: number, Longitude: number, Title: string) {
    this.center = {
      lat: Latitude,
      lng: Longitude,
    };
    this.markers = [];
    this.markers.push(
      {
        position: { lat: Latitude, lng: Longitude },
        draggable: this.IsDraggable,
        // label: {
        //   color: 'red',
        //   text: element.AddressName,
        // },
        title: Title,
        //options: { animation: google.maps.Animation.BOUNCE },
      });
    // console.log('this.markers');
    // console.log(this.markers);
  }

  onCloseButtonClick() {
    this.closeDialog();
  }
  onCancelClick() {
    this.userAddresses = [];
    this.closeDialog();
  }
  onDragEndMarker(event: google.maps.MouseEvent, index) {
    // console.log('Marker Changed');
    if (this.userAddresses && this.userAddresses.length > 0) {
      this.userAddresses[index].Latitude = event.latLng.lat();
      this.userAddresses[index].Longitude = event.latLng.lng();
    }
    else if (this.Lat && this.Long) {
      this.Lat = event.latLng.lat();
      this.Long = event.latLng.lng();
      // console.log(this.Lat);
      // console.log(this.Long);
    }
  }
  closeDialog(ReloadParentData: boolean = false) {
    this.dialogRef.close(ReloadParentData ? this.userAddresses : []);
  }

  getGeoLocation(address: string): Observable<any> {
    let geocoder = new google.maps.Geocoder();
    return Observable.create(observer => {
      geocoder.geocode({
        'address': address
      }, (results, status) => {
        //console.log('results');
        //console.log(results);
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next(results[0].geometry.location);
          observer.complete();
        }
        else if (status == google.maps.GeocoderStatus.ZERO_RESULTS) {
          setTimeout(() => {
            this._toastrService.Warning(this.currentAppLanguage.data.ADDRESS.ERROR_MESSAGE_MAP_ZERO_RESULTS, Constant.WarningToastrTitle);
          }, 10);
        } else {
         // console.log('Error: ', results, ' & Status: ', status);
          observer.error();
        }
      });
    });
  }

   onSubmit() {
    let Event = ''; let _data; let _searchstring = '';
     if(((this.Lat == null) || (this.Lat == undefined ) && (this.Long == null) || (this.Long == undefined ) )){
      this.onSearchClick();
     }

     setTimeout(() => {
    if(this.IsSearchTextVisible , this.txtSearchOnMap)
    if (this.IsSearchTextVisible && this.txtSearchOnMap) {
      if (E.ConvertToString(this.txtSearchOnMap.nativeElement.value) !== '') {
        _searchstring = E.ConvertToString(this.txtSearchOnMap.nativeElement.value);
      }
      else {
        return;
      }
    }
    if (this.IsSearchOnMap) {
      Event = 'SearchOnMap';
      _data = new MapAddressOutputModel(this.Lat, this.Long, _searchstring);
    }
    this.dialogRef.close({ event: Event, data: _data });
  }, 1000);
  }

  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
}

import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { Constant } from 'app/Classes/constant';
import { locale as english } from 'app/quick-post/quick-post/i18n/en';
import { locale as french } from 'app/quick-post/quick-post/i18n/fr';
import { locale as german } from 'app/quick-post/quick-post/i18n/de';
import { locale as portuguese } from 'app/quick-post/quick-post/i18n/pt';
import { locale as gujarati } from 'app/quick-post/quick-post/i18n/gj';
import { locale as hindi } from 'app/quick-post/quick-post/i18n/hn';
import { CoreTranslationService } from '@core/services/translation.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '@core/services/config.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthenticationService } from 'app/auth/service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TextBoxComponent } from 'app/CustomComponents/text-box/text-box.component';
import { DeleteInputModel, DownloadFileInputModel, DownloadFileOutputModel } from 'app/Models/output-model';
import { E } from 'assets/extensions/extensions';
import { LightGallery } from 'lightgallery/lightgallery';
import { QuickPostLoadDataOutputModel, QuickPostCreateUpdateInputModel, QuickPostDocumentListOutputModel } from '../quick-post-model.model';
import { TrackersComponent } from '../trackers/trackers.component';
import { MapModalType, Status } from 'app/enums';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { GlobalService } from 'app/Services/global.service';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { MapAddressComponent } from 'app/address/map-address/map-address.component';
import { MapAddressDataModel, MapAddressOutputModel } from 'app/address/map-address/map-address-data-model.model';
import Swal from 'sweetalert2';
import { QuickPostService } from '../quick-post/quick-post.service';
import Quill from 'quill';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import video from 'lightgallery/plugins/video';
import lgvideo from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import Zoom from 'lightgallery/plugins/zoom';
import vimeoThumbnail from 'lightgallery/plugins/vimeoThumbnail';
import thumbNail from 'lightgallery/plugins/thumbnail';
import autoPlay from 'lightgallery/plugins/autoplay';
import fullScreen from 'lightgallery/plugins/fullscreen';
import rotate from 'lightgallery/plugins/rotate';
import { Router } from '@angular/router';
import { UploadManagerComponent } from 'app/filemanager/upload-manager/upload-manager.component';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  
})
export class UploadComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  @ViewChild('txtTitle') txtTitle: TextBoxComponent;
  @ViewChild('QuickPostGallery') lightGallery!: LightGallery;
  @ViewChild('nav') nav: NgbNav;
  @ViewChild('fileInput') fileInput;
  _ImageUrlAPI: string = Constant.API + 'api/UserQuickPost/UploadUserQuickPostDocuments';
  public uploader: FileUploader = new FileUploader({
    url: this._ImageUrlAPI,
    isHTML5: true,
    method: "PUT",
    itemAlias: "MultiFileUploaderQuickPost",
    disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
    formatDataFunction: (item: FileItem) => item._file,
    formatDataFunctionIsAsync: false,
    maxFileSize: 1 * 1024 * 1024 * 1024,
    //Notes: do not add headers here.
    authToken: `Bearer ` + this.authenticationService.currentUserValue.Token,
    allowedFileType: ["image", "tiff", "bmp", "png", "jpg", "jpeg", "video", "mp4", "mpeg", "mov", "avi", "ogv", "webm"]
  });
  uploaderWarning:boolean=false;
  forgotuploadWarning:boolean=false;
  settings = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: true,
    slideVideo: true,
    pager:false,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide:false,
    plugins: [Zoom, thumbNail, autoPlay, fullScreen, rotate, lgvideo, lgVideo, video, vimeoThumbnail] //share,comment (commented for the future use)
  };
  public quillEditorContent = ``;
  public loading = false;
  public submitted = false;
  currentAppLanguage: any;
  public coreConfig: any;
  public error = '';
  dialogTitle: string;
  Ratings: any = [];
  MoodItem:any=[];
  _data: number = 0;
  public tab = 1;public IsForAbandon: boolean = false;
  public frmQuickpost: FormGroup;
  public IsForEdit: boolean = false;
  public PlaceholderQuickPost;
  // Basic Time Picker
  CurrentDateTime: Date = E.GetCurrentLocaleDateTime();
  public spinnerTPtime = { hour: this.CurrentDateTime ? this.CurrentDateTime.getHours() : 0, minute: this.CurrentDateTime ? this.CurrentDateTime.getMinutes() : 0 };
  public spinnerTP = false;
  public spinnerTftime = { hour: this.CurrentDateTime ? this.CurrentDateTime.getHours() : 0, minute: this.CurrentDateTime ? this.CurrentDateTime.getMinutes() : 0 };
  public spinnerTf = false;
  private needRefresh = false;
  private subDeleteQuickPostAtt$?: Subscription;
  public deleteDocumentsInputModel: Array<DeleteInputModel> = [];
  newGalleryItems: any = [];
  GalleryItems: any = [];
  GalleryItems1: any = [];
  Moods: any = [];
  MoodsRating: any = [];
  trackerId: number = 0;
  tracker: TrackersComponent;
  quickpostLoadDataOutputModel: QuickPostLoadDataOutputModel = new QuickPostLoadDataOutputModel();
  userQuickPosts: QuickPostCreateUpdateInputModel;
  deleteInputModel: DeleteInputModel = new DeleteInputModel();
  btnNameJorD: string;
  galleryRefresh:LightGallery;
  public downloadFileInputModel: DownloadFileInputModel;
  private subDownloadQuickpostAtt$?: Subscription;
  public customTagselected = [];
  public customTagselected1 = [];
  public customTags: any[] = [];
  public customTagNames = ['Noosom'];
  DocumentList: QuickPostDocumentListOutputModel[]= [];
  DocumentList1: QuickPostDocumentListOutputModel[]= [];
  UserQuickpostDocument: number[];
  cache: { [key: string]: string } = {};
  infoTooltip: any;
  urls: string[] = [];
  templcstg = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg'))) 
  timeformat;
  uploadCount=0;
  dateformat: string;
  constructor(
    private _coreTranslationService: CoreTranslationService,
    private _translateService: TranslateService,
    private dialogRef: MatDialogRef<UploadComponent>,
    private dialog: MatDialog,
    private _coreConfigService: CoreConfigService,
    private authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private loadingScreenService: LoadingScreenService,
    private globalService: GlobalService,
    private _toastrService: CustomToastrService,
    private encrDecr: EncrDecrService,
    private _QuickPostService: QuickPostService,
    private el: ElementRef,
    private _router:Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this._coreTranslationService.translate(english, french, german, portuguese, gujarati, hindi);
    this._translateService.onLangChange.subscribe(lang => {
      // console.log('lang');
      // console.log(lang.lang);
      setTimeout(() => {
        let _LangJsonObj = this.GetCurrentLanguageJsonObject(lang.lang);
        if (_LangJsonObj)
          this.currentAppLanguage = _LangJsonObj;
      }, 100);
    })
    this._unsubscribeAll = new Subject();
    this._data=data;
    this.globalService.currentUrls.subscribe(urls => this.urls = urls)
    this.dateformat=this.globalService.getDateFormat().toUpperCase()
    if(this.globalService.getTimeFormat()=='HH:mm'){
      this.timeformat= false;
    }else if(this.globalService.getTimeFormat()=='hh:mm a'){
     this.timeformat= true;
    }
  }

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      if (this.lightGallery)
      this.galleryRefresh.refresh();
      this.needRefresh = false;
    }
    if(this.urls.length>0){
     // console.log(this.urls,'urlcheck')
     this.uploadDocumentsFromCDN(this.urls)
      this.globalService.clearUrls();
      this.urls=[]
    }
  }
  onBeforeClose = async (detail) => {
    this.galleryRefresh.refresh();
  }
  onInitGallery = (detail): void => {
    detail.instance.settings.licenseKey=this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey
    // console.log(this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey)
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customButton = `<button id="lg-custom-button-delete" style="padding-top:8px" class="lg-icon"><img style="height:30px;" src="assets/images/icons/trash.png"></button>`;
    const tagButton = `<button id="lg-custom-button-download" class="custombutton12 lg-icon"><img style="height:30px" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    // const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    // <img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
      const $toolbar = this.lightGallery.$toolbar;
      $toolbar.append(customButton);
      $toolbar.append(tagButton);
      $toolbar.append(customHelpButton);
      //$toolbar.append(refreshButton);
      document.getElementById("lg-custom-button-delete").addEventListener("click", async () => {
        const idname = detail.instance.galleryItems[detail.instance.index];
        await this.onDeleteDocument(idname.alt)
        let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
         const updatedGalleryItems = galleryItems1.filter((item, index) => index !== detail.instance.index);
         detail.instance.updateSlides(updatedGalleryItems,Math.max(detail.instance.index - 1, 0));
         detail.instance.slidesUpdated = true
         let slidesUpdated = false;
         //detail.instance.refresh();
      });
      document.getElementById("lg-custom-button-download").addEventListener("click", () => {
        const downloadDocument:QuickPostDocumentListOutputModel = new QuickPostDocumentListOutputModel;
        const idname = detail.instance.galleryItems[detail.instance.index];
        if(idname.src){
          downloadDocument.DocumentPath = idname.src;}
          else if(idname.video){
            downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
          }
        downloadDocument.UserQuickPostDocumentId=idname.alt;
     // console.log(downloadDocument)
        this.onDownloadDocument(downloadDocument)
      });
      document.getElementById("lg-custom-button-help").addEventListener("click", () => {
        this.supportFunction();
      });
      // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
      //   detail.instance.refresh();
      // });
  };

  onInitGallery1 = (detail): void => {
    detail.instance.settings.licenseKey=this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey
    // console.log(this.authenticationService.currentUserValue.Parameter.LightGalleryLicenseKey)
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    const customButton = `<button id="lg-custom-button-delete1" style="padding-top:8px" class="lg-icon"><img style="height:30px;" src="assets/images/icons/trash.png"></button>`;
    const tagButton = `<button id="lg-custom-button-download1" class="custombutton12 lg-icon"><img style="height:30px" class="custombutton12" src="assets/images/icons/download.png"></button>`;
    const customHelpButton = `<button id="lg-custom-button-help1" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    // const refreshButton = `<button id="lg-custom-button-refresh" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    // <img class="svg-icon" style="height:28px;" src="assets/images/pages/refresh-ccw.svg"></button>`
      const $toolbar = this.lightGallery.$toolbar;
      $toolbar.append(customButton);
      $toolbar.append(tagButton);
      $toolbar.append(customHelpButton);
      //$toolbar.append(refreshButton);
      document.getElementById("lg-custom-button-delete1").addEventListener("click", async () => {
        const idname = detail.instance.galleryItems[detail.instance.index];
        await this.onDeleteDocument(idname.alt)
        let galleryItems1 = JSON.parse(JSON.stringify(detail.instance.galleryItems));
         const updatedGalleryItems = galleryItems1.filter((item, index) => index !== detail.instance.index);
         detail.instance.updateSlides(updatedGalleryItems,Math.max(detail.instance.index - 1, 0));
         detail.instance.slidesUpdated = true
         let slidesUpdated = false;
         //detail.instance.refresh();
      });
      document.getElementById("lg-custom-button-download1").addEventListener("click", () => {
        const downloadDocument:QuickPostDocumentListOutputModel = new QuickPostDocumentListOutputModel;
        const idname = detail.instance.galleryItems[detail.instance.index];
        if(idname.src){
          downloadDocument.DocumentPath = idname.src;}
          else if(idname.video){
            downloadDocument.DocumentPath = JSON.parse(idname.video).source[0].src;
          }
        downloadDocument.UserQuickPostDocumentId=idname.alt;
     // console.log(downloadDocument)
        this.onDownloadDocument(downloadDocument)
      });
      document.getElementById("lg-custom-button-help1").addEventListener("click", () => {
        this.supportFunction();
      });
      // document.getElementById("lg-custom-button-refresh").addEventListener("click", () => {
      //   detail.instance.refresh();
      // });
  };

  supportFunction() {

    Swal.fire({
      title: "Help!",
      text: "Information about the icons in the Light Gallery.",
      imageUrl: 'assets/images/pages/raw-icon-labels.png',
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }

    })
  }

  async onDeleteDocument(userQuickPostDocumentID: number) {
    if (!userQuickPostDocumentID) {
      return;
    }
  
    try {
      await this.showConfirmationDialog();
      const result = await this.DeleteQuickPostDocument([userQuickPostDocumentID]);
  
      if (result) {
        // Next line of code after both function1 and function2 have completed
      }
    } catch (error) {
    }
  }
  
  showConfirmationDialog(): Promise<void> {
    return new Promise<void>((resolve) => {
      Swal.fire({
        title: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TITLE_QUICKPOST_IMAGE.replace('Quick Post', this.btnNameJorD+' Entry'),
        text: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TEXT,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#7367F0',
        cancelButtonColor: '#E42728',
        confirmButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
        cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
      }).then((result) => {
        if (result.value) {
          resolve();
        }
      });
    });
  }
  
  async DeleteQuickPostDocument(UserQuickPostDocumentIds: Array<number>): Promise<boolean> {
    this.loadingScreenService.startLoading();
  
    try {
      if (UserQuickPostDocumentIds.length < 1) {
        return false;
      }
  
      this.deleteDocumentsInputModel = [];
       this.UserQuickpostDocument = UserQuickPostDocumentIds
      UserQuickPostDocumentIds.forEach((userQuickPostDocumentId) => {
        this.deleteDocumentsInputModel.push({
          DeleteID: userQuickPostDocumentId,
          UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId,
        });
      });
  
      const result = await this.postDataAndHandleResponse();
  
      return result;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    } finally {
      this.loadingScreenService.stopLoading();
    }
  }
  
  private postDataAndHandleResponse(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const path = 'api/UserQuickPost/DeleteUserQuickPostDocuments';
      this.subDeleteQuickPostAtt$ = this.globalService.PostDataById(this.deleteDocumentsInputModel, path).subscribe(
        (data) => {
          const result = JSON.parse(JSON.stringify(data));
  
          if (result.status === Status.Success) {
            // Handle success
            this.handleSuccess(result);
            resolve(true);
          } else {
            // Handle failure
            this.handleFailure(result);
            resolve(false);
          }
        },
        (error) => {
          // Handle network error
          this.handleNetworkError(error);
          resolve(false);
        }
      );
    });
  }
  
  private handleSuccess(result: any): void {
    Swal.fire({
      icon: 'success',
      title: 'Deleted!',
      text: E.ConvertToString(result.message).replace('Quick post', this.btnNameJorD+' Entry'),
      customClass: {
        confirmButton: 'btn btn-success',
      },
    });
  
    // Modify this part as needed
    this.DocumentList = this.DocumentList.filter((x) => !this.UserQuickpostDocument.includes(x.UserQuickPostDocumentId));
    const indexToRemove: number = this.GalleryItems.findIndex((item) => item.id == this.UserQuickpostDocument);
    if (indexToRemove !== -1) {
      this.GalleryItems.splice(indexToRemove, 1);
    }
    const indexToRemovenew: number = this.GalleryItems1.findIndex((item) => item.id == this.UserQuickpostDocument);
    if (indexToRemovenew !== -1) {
      this.GalleryItems1.splice(indexToRemovenew, 1);
    }
  }
  
  private handleFailure(result: any): void {
    this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
  }
  
  private handleNetworkError(error: any): void {
    this._toastrService.Error(error, Constant.ErrorToastrTitle);
    this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
  }


  async onDownloadDocument(document: QuickPostDocumentListOutputModel) {
    if (document) {
      await this.DownloadMyAddressDocument(document);
    }
  }
  async DownloadMyAddressDocument(Document: QuickPostDocumentListOutputModel, IsDownloadAll = false) {
    //console.log('DeleteMyAddressDocument');
    this.loadingScreenService.startLoading();
    if (typeof Document === 'undefined' || Document == null) {
      return false;
    }
    this.downloadFileInputModel = {
      DownloadID: Document.UserQuickPostDocumentId,
      FilePath: Document.DocumentPath,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    };
    let promise = new Promise((resolve, reject) => {
      var Result: boolean = false;
      const path = 'api/UserQuickPost/DownloadUserQuickPostDocuments';
      this.subDownloadQuickpostAtt$ = this.globalService.PostDataById(this.downloadFileInputModel, path).subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          if (result.status == Status.Success) {
            let _downloaded = <DownloadFileOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            var sampleArr = this.globalService.base64ToArrayBuffer(E.ConvertToString(_downloaded.FileBase64String));
            this.globalService.saveByteArray(_downloaded.FileName, sampleArr, _downloaded.FileMimeType);
            if (IsDownloadAll === false)
              this._toastrService.Success('Downloaded Successfully.', Constant.SuccessToastrTitle);
            //console.log(this.DocumentList);
            Result = true;
          }
          else {
            this._toastrService.Error(result.message, Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
          resolve(Result);
        }
      );
    });
    return await promise;
  }

  ngOnInit(): void {
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
    this.currentAppLanguage = this.GetCurrentLanguageJsonObject(this.coreConfig.app.appLanguage);
    if(this.templcstg){
      if(this.templcstg.DiaryOrJournal == '2'){
        this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_JOURNAL;
      }
      else{
        this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_DIARY;
      }
    }
    else if(this.authenticationService.currentUserValue.NoosomTypeId === 2){
      this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_JOURNAL;
     }
     else{
       this.btnNameJorD= this.currentAppLanguage.data.QUICKPOST.HEADING_TEXT_DIARY;
     }
     this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT;
    //  const editorElement: HTMLElement = this.el.nativeElement.querySelector('#editor');
    
    //  // Check if the element is not null before accessing the style property
    //  if (editorElement) {
    //    editorElement.style.height = '400px';
    //  }
    this.dialogTitle=this.btnNameJorD;
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCloseButtonClick();
      }
    });
    this.frmQuickpost = this._formBuilder.group({
      Title: ['', Validators.required],
      DiaryDate: [new Date(), Validators.required],
      DiaryTimeFrom: [, Validators.required],
      DiaryTimeTo: [, Validators.required],
      Location: [''],
      TagCategory: [],
      PostContent: ['', Validators.required],
      QuickPostTypeId: [, Validators.required],
      MoodId:[],
      MoodRatingId:[],
    });
    if (this._data == 0) {
      setTimeout(() => {
        this.customTagNames.forEach((c, i) => {
          this.customTags = [...this.customTags, { id: i, name: c, tag: true }];
          this.customTagselected = [...this.customTagselected, { id: i, name: c, tag: true }];
        });
      }, 10);
    }
    this.uploader.onAfterAddingFile = (file: any) => {
      if((this.uploader.queue.length>0) && (this.uploader.isUploading==false)){
        this.forgotuploadWarning=true;}
      file.withCredentials = false;
    };
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      fileItem.withCredentials = false;
    };
    this.uploader.onBeforeUploadItem = (fileItem) => {
      this.loadingScreenService.startLoading();
      this.uploader.setOptions({
        headers: [
          { name: 'Access-Control-Allow-Origin', value: "*" },
          { name: 'FileSize', value: fileItem.file.size },
          { name: 'UserDetailsID', value: E.ConvertToString(this.authenticationService.currentUserValue.UserDetailsId) },
          { name: 'UserQuickPostID', value: E.ConvertToString(this._data) }]
      });
      return fileItem;
    };
    
    this.uploader.response.subscribe(async result => {
       //console.log(result)
      if (result) {
        this.loadingScreenService.startLoading();
        let _result = JSON.parse(result);
        if(this.uploader.progress!=100){
          this.uploaderWarning=true;
          this.loadingScreenService.startLoading();
      }else if(this.uploader.progress==100){
        this.uploaderWarning=false;
        this.forgotuploadWarning=false;
        //this.uploadCount = 0;
        this.loadingScreenService.stopLoading();
      }
        if (_result.status == Status.Success) {
          this._toastrService.Success("File Uploaded Successfully")
          this.uploadCount = this.uploadCount+1;
          let _QuickPostDocument = <QuickPostDocumentListOutputModel>JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, _result.data));
          if (_QuickPostDocument.FileType.toLocaleLowerCase() == "documents") {
            this.DocumentList.push(_QuickPostDocument);
            this.DocumentList1.push(_QuickPostDocument);
          }
          else if (_QuickPostDocument.FileType.toLocaleLowerCase() == "images" || "videos") {
           await this.AddItemToGallery(_QuickPostDocument.UserQuickPostDocumentId, _QuickPostDocument.DocumentPath, _QuickPostDocument.DocumentOldName,_QuickPostDocument.Size ,_QuickPostDocument.FileType);
           await this.AddItemToGallery1(_QuickPostDocument.UserQuickPostDocumentId, _QuickPostDocument.DocumentPath, _QuickPostDocument.DocumentOldName,_QuickPostDocument.Size ,_QuickPostDocument.FileType);
             this.needRefresh = true;
          }
        }
        else if(_result.status == Status.StorageFull){
          // this.uploader.destroy();
          this.loadingScreenService.stopLoading();
           this.uploader.cancelAll();
           // this.uploader.removeFromQueue();
           // this.uploader.cancelItem()
           this.uploader.clearQueue()
           Swal.fire({
             title: "Storage Full",
             text: _result.message,
             icon: 'error',
             showCancelButton: true,
             confirmButtonColor: '#7367F0',
             cancelButtonColor: '#E42728',
             confirmButtonText: "Buy More Storage",
             cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
             customClass: {
               confirmButton: 'btn btn-primary',
               cancelButton: 'btn btn-danger ml-1'
             }
           })
           .then(
             (async (result) => {
               if (result.value) {
                 //this.uploader.clearQueue();
                 this.dialogRef.close(true);
                 this._router.navigate(['/pages/pricing'])
               }
             })
           );
               
         }
      }
      // console.log("error2")
      // this.loadingScreenService.stopLoading();
    });

    setTimeout(() => {
      this.loadingScreenService.startLoading();
      const path = 'api/UserQuickPost/GetUserQuickPostLoadData';
      let response: Observable<any>;
      response = this.globalService.PostDataById(this._data, path);
      response.subscribe(
        (result) => {
          this.loadingScreenService.stopLoading();
          if (result.status == Status.Success) {
            this.quickpostLoadDataOutputModel = <QuickPostLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
            if (this.quickpostLoadDataOutputModel.QuickPostDocumentList) {
              this.quickpostLoadDataOutputModel.QuickPostDocumentList.forEach(async(doc) => {
                //console.log(this.quickpostLoadDataOutputModel.QuickPostDocumentList)
                if (doc.FileType.toLocaleLowerCase() == "documents") {
                  this.DocumentList.push(doc);
                }
               else if (doc.FileType.toLocaleLowerCase() == ("images") || ("videos")) {
                  //console.log(doc.FileType.toLocaleLowerCase())
                  await this.AddItemToGallery(doc.UserQuickPostDocumentId, doc.DocumentPath, doc.DocumentOldName, doc.Size,doc.FileType);
                  this.needRefresh = true;
                }
              });
            }
            if (this.quickpostLoadDataOutputModel.UserQuickPost) {
              this.userQuickPosts = this.quickpostLoadDataOutputModel.UserQuickPost;
              let _diaryTimefrom = E.ConvertToDate(this.userQuickPosts.DiaryTimeFrom);
              let _diaryTimeto = E.ConvertToDate(this.userQuickPosts.DiaryTimeTo);
              if(this.quickpostLoadDataOutputModel.MoodList){
                this.quickpostLoadDataOutputModel.MoodList.forEach((mood)=>{
                  this.Moods=this.quickpostLoadDataOutputModel.MoodList;
                  this.AddMoodList(mood.EmotionPath,mood.MoodId,mood.MoodName,mood.OrderSequence,mood.Rating);
                  });
              }
              if (this.quickpostLoadDataOutputModel.MoodRatingList) {
                this.MoodsRating=this.quickpostLoadDataOutputModel.MoodRatingList;
                this.quickpostLoadDataOutputModel.MoodRatingList.forEach((mood)=>{
                this.AddMoodRating(mood.IconPath,mood.MoodRatingId,mood.MoodRatingName,mood.OrderSequence);
                });
             }  
              this.customTags = JSON.parse(E.ConvertToString(this.userQuickPosts.TagCategory));
              setTimeout(() => {
                this.customTagselected = [];
                this.customTags.forEach(x => {
                  if (typeof x.tag === 'undefined' || x.tag == true) {
                    this.customTagselected = [...this.customTagselected, { id: x.id, name: x.name, tag: true }];
                  }
                });
                this.customTagselected1=this.customTagselected
              }, 10);
              let _postContent = this.userQuickPosts.PostContent;
              let modid= 'rating'+this.userQuickPosts.MoodId;
              let rateingsid = 'bar'+this.userQuickPosts.MoodRatingId;
              this.frmQuickpost.setValue({
                Title: this.userQuickPosts.Title,
                DiaryDate: E.ConvertToDate(this.userQuickPosts.DiaryDate),
                DiaryTimeFrom: { hour: _diaryTimefrom ? _diaryTimefrom.getHours() : 0, minute: _diaryTimefrom ? _diaryTimefrom.getMinutes() : 0 },
                DiaryTimeTo: { hour: _diaryTimefrom ? _diaryTimeto.getHours() : 0, minute: _diaryTimeto ? _diaryTimeto.getMinutes() : 0 },
                Location: this.userQuickPosts.Location,
                PostContent: _postContent,
                TagCategory: this.userQuickPosts.TagCategory,
                QuickPostTypeId: this.userQuickPosts.QuickPostTypeId,
                MoodId:modid,
                MoodRatingId:rateingsid,
              });
            }
            if (this.quickpostLoadDataOutputModel.QuickPostTypeList.length > 0) {
              this.quickpostLoadDataOutputModel.QuickPostTypeList = this.quickpostLoadDataOutputModel.QuickPostTypeList.map((p) =>
                p.QuickPostTypeName.toLocaleLowerCase() == 'private' ? p : { ...p }
              );
              let _private = this.quickpostLoadDataOutputModel.QuickPostTypeList.filter(x => x.QuickPostTypeName.toLocaleLowerCase() == 'private');
              if (_private && _private[0] && this.frmQuickpost.get('QuickPostTypeId').value == null)
                this.frmQuickpost.get('QuickPostTypeId').setValue(_private[0].QuickPostTypeId);
            }
          }
          else {
          }
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = error;
          this.loadingScreenService.stopLoading();
        }
      );
    }, 500);
  } 

  uploadDocumentsFromCDN(cdnUrls: string[]) {
    this.fetchDocuments(cdnUrls).then(files => {
      if (files && files.length > 0) {
        // this.uploader.clearQueue();
        this.uploader.addToQueue(files);
        // this.uploader.uploadAll();
  
        // this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        //   console.log('DocumentUpload:uploaded:', item, status, response);
        //   alert('Document uploaded successfully');
        // };
      }
    }).catch(error => {
      console.error('Error fetching documents from CDN:', error);
    });
  }
  
  fetchDocuments(cdnUrls: string[]): Promise<File[]> {
    return Promise.all(cdnUrls.map(cdnUrl => this.fetchDocument(cdnUrl)));
  }
  
  fetchDocument(cdnUrl: string): Promise<File> {
    return fetch(cdnUrl)
      .then(response => response.blob())
      .then(blob => {
        const contentType = blob.type;
        const fileName = cdnUrl.split('/').pop();
        return new File([blob], fileName, { type: contentType });
      });
  }

  triggerFileUpload() {
    this.fileInput.nativeElement.click();
  }
  

  checkQueueLength(queuLength){
    if((queuLength>0) && (this.uploader.isUploading==false)){
      this.forgotuploadWarning=true;}
      else if(queuLength==0)
        {this.forgotuploadWarning=false;
          this.uploaderWarning=false;
        }
  }

  onTabActive(tabNumber:number){
    if(tabNumber==1){this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT;}
    else if(tabNumber==2){this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT3;}
    else if(tabNumber==3){this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT1;}
    else if(tabNumber==4){this.infoTooltip= this.currentAppLanguage.data.QUICKPOST.INFO_TEXT2;}
    this.tab=tabNumber;
  }
  openFilemanager(){
    this.openDialog(UploadManagerComponent)
  }

  openDialog(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "66%"
    dialogConfig.height="60%"
    dialogConfig.position={
      top:"10%"
    }
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        this.uploader.uploadAll();
      }
    );
  }

  AddMoodList(url:string,id:number,name:string,sequence:number,rating:number){
    this.MoodItem= [
      ...this.MoodItem,
      {
        moodpath:url,
        moodid:id,
        moodname:name,
        moodorder:sequence,
        moodrating:rating,
      },
    ];
   }

  AddMoodRating(url:string,id:number,name:string,order:number){
    this.Ratings= [
      ...this.Ratings,
      {
       _url:url,
       _id:id,
       _name:name,
       _order:order,
      }
    ];
  }

  onSearchOnMapClick() {
    this.openDialogMapAddress();
  }

  addTagMethod = (term) => {
    //console.log(term);
    this.customTags = [...this.customTags, { id: this.customTags.length, name: term, tag: true }];
    //console.log(this.customTags);
    //console.log(this.customTagselected);
    return { id: term, name: term };
  };

  removeTagMethod(term) {
    //console.log('removeTagMethod');
    //console.log(term);
    //console.log(term.index);
    //console.log(this.customTags[term.index].tag)
    this.customTags[term.index].tag = false;
    //console.log(this.customTags);
  }

  selectAddTagMethod(obj) {
    //console.log('selectAddTagMethod');
    //console.log(this.customTags.indexOf(obj));
    if (this.customTags && obj) {
      if (this.customTags.indexOf(obj) > -1) {
        //console.log(obj);
        this.customTags[obj.id].tag = true;
      }
    }
    //console.log(this.customTags);
  }

  OnQuickPostTimeChangeFrom($event){
    let timefrom = this.frmQuickpost.get('DiaryTimeFrom').value;
    let timeto = this.frmQuickpost.get('DiaryTimeTo').value;
    if (timefrom && timeto) { 
      if(timefrom.hour>=timeto.hour){
      if(timefrom.minute>timeto.minute){
      //this.frmQuickpost.get('DiaryTimeFrom').disable();
      this.frmQuickpost.get('DiaryTimeFrom').setValue(timeto)
      }
    }
    }
  }

  onSubmit(tabs: boolean) {
    if(this.uploaderWarning){
      Swal.fire({
        title: 'Warning',
        text: 'Your documents or files are still uploading. Please note that any remaining files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to continue?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.uploaderWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
     if(this.forgotuploadWarning){
      Swal.fire({
        title: 'Warning',
        text: 'You have some documents or files to upload. Please note that all your files will not be uploaded or will be terminated if you proceed to submit the form. Are you sure you want to submit the form?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-success'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.forgotuploadWarning=false;
          this.onSubmit(tabs)
        }
        else{
          this.nav.select(3);
        }
      });
      return;
     }
    this.submitted = true;
    if (this.frmQuickpost.invalid) {
      return;
    }
    this.submitted = false;
    if (this.userQuickPosts == null) {
      this.userQuickPosts = new QuickPostCreateUpdateInputModel();
    }
    this.userQuickPosts.UserDetailsId = this.authenticationService.currentUserValue.UserDetailsId;
    this.userQuickPosts.Title = this.frmQuickpost.get('Title').value;
    this.userQuickPosts.DiaryDate = E.GetDateStringOnly(this.frmQuickpost.get('DiaryDate').value);
    this.userQuickPosts.DiaryTimeFrom = E.GetMinDateWithTime(this.spinnerTPtime.hour, this.spinnerTPtime.minute).toISOString();// '1900-01-01 10:30:00.000';
    this.userQuickPosts.DiaryTimeTo   = E.GetMinDateWithTime(this.spinnerTftime.hour, this.spinnerTftime.minute).toISOString();// '1900-01-01 10:30:00.000';
    this.userQuickPosts.Location = this.frmQuickpost.get('Location').value;
    this.userQuickPosts.QuickPostTypeId = this.frmQuickpost.get('QuickPostTypeId').value;
  //  console.log( this.userQuickPosts.DiaryTimeFrom)
    
    this._QuickPostService.getTracker().subscribe(x => {
      if(x)
      this.userQuickPosts.MoodId = x;
    else
    this.userQuickPosts.MoodId = 1
    });
    
    this._QuickPostService.getRating().subscribe(x => {
      if(x)
      this.userQuickPosts.MoodRatingId = x;
      else
      this.userQuickPosts.MoodRatingId = 3
    });


    let _tags = JSON.stringify(this.customTags);
    this.userQuickPosts.TagCategory = _tags;
    this.userQuickPosts.PostContent = E.ConvertToString(this.frmQuickpost.get('PostContent').value);
    if (E.ConvertToNumber(this.userQuickPosts.UserQuickPostId) == 0 && this.IsForEdit == false) {
      this.userQuickPosts.CreatedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userQuickPosts.CreatedDate = E.GetTodayDateStringOnly();
      this.userQuickPosts.CreatedTime = E.GetTodayTimeStringOnly();
      this.userQuickPosts.ChangedDate = E.GetTodayDateStringOnly();
      this.userQuickPosts.ChangedTime = E.GetTodayTimeStringOnly();
    }
    else {
      this.userQuickPosts.ChangedBy = this.authenticationService.currentUserValue.UserDetailsId;
      this.userQuickPosts.ChangedDate = E.GetTodayDateStringOnly();
      this.userQuickPosts.ChangedTime = E.GetTodayTimeStringOnly();
    }
    if (this.tab <= 2 ||this.IsForEdit) {
    this.loading = true;
    this.loadingScreenService.startLoading();
    const path = 'api/UserQuickPost/CreateUpdateUserQuickPost';
    let response: Observable<any>;
    response = this.globalService.PostDataById(this.userQuickPosts, path);
    response.subscribe(
      (result) => {
        this.loading = false;
        this.loadingScreenService.stopLoading();
        var message = ""; var errormessage = "";
        if (result.status == Status.RecordAdded) {
          message = this.currentAppLanguage.data.QUICKPOST.TOAST_MESSAGE_QUICKPOST_ADDED_SUCCESSFULLY.replace('Quick Post', this.btnNameJorD+' Entry');
          this.tab = this.tab + 1;
            this.nav.select(this.tab)
            const jsonString = this.encrDecr.get(Constant.EncrDecrKey, result.data);
            const parsedObject = JSON.parse(jsonString);
            this._data = parsedObject.Data.UserQuickPostId
            this.userQuickPosts.UserQuickPostId=parsedObject.Data.UserQuickPostId;
            this.loadData();
            this.IsForAbandon=true;
        }
        else if (result.status == Status.RecordUpdated) {
          message = this.currentAppLanguage.data.QUICKPOST.TOAST_MESSAGE_QUICKPOST_UPDATED_SUCCESSFULLY.replace('Quick Post', this.btnNameJorD+' Entry');
          if(!tabs)
          {this.tab=this.tab+1
          this.nav.select(this.tab)}
        }
        else {
          errormessage = E.ConvertToString(result.message);
        }
        if (message.length > 0) {
          this._toastrService.Success(message, Constant.SuccessToastrTitle);
          if ((this.tab == 1) || tabs) {
          this.dialogRef.close(true);
          this.frmQuickpost.reset();}
          this.frmQuickpost.patchValue({
            // AddressFromDate: new Date(),
            // AddressToDate: new Date()
          });
          // this.txtTitle.textBox.nativeElement.focus();

        }
        else {
          this._toastrService.Error(errormessage, Constant.ErrorToastrTitle);
        }
      },
      (error) => {
        this._toastrService.Error(error, Constant.ErrorToastrTitle);
        this.error = error;
        this.loading = false;
        this.loadingScreenService.stopLoading();
      }
    );
    }
    else if (this.tab >= 2 && this.tab <= 4) {
      this.tab = this.tab + 1;
      this.nav.select(this.tab)
      if (tabs) {
        this.dialogRef.close(true);
        this.frmQuickpost.reset();
      }
    }
  }


  loadData(){
    const path = 'api/UserQuickPost/GetUserQuickPostLoadData';
    let response: Observable<any>;
    response = this.globalService.PostDataById(this._data, path);
    response.subscribe(
      (result) => {
        this.loadingScreenService.stopLoading();
        if (result.status == Status.Success) {
          this.quickpostLoadDataOutputModel = <QuickPostLoadDataOutputModel>JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, result.data)));
          if (this.quickpostLoadDataOutputModel.UserQuickPost) {
            if (this.quickpostLoadDataOutputModel.MoodRatingList) {
              this.quickpostLoadDataOutputModel.MoodRatingList.forEach((mood)=>{
              this.AddMoodRating(mood.IconPath,mood.MoodRatingId,mood.MoodRatingName,mood.OrderSequence);
              });
           }
        
           if(this.quickpostLoadDataOutputModel.MoodList){
             this.quickpostLoadDataOutputModel.MoodList.forEach((mood)=>{
               this.AddMoodList(mood.EmotionPath,mood.MoodId,mood.MoodName,mood.OrderSequence,mood.Rating);
               });
               let modid= 'rating'+this.userQuickPosts.MoodId;
              let rateingsid = 'bar'+this.userQuickPosts.MoodRatingId;
              this.frmQuickpost.get('MoodId').setValue(modid)
              this.frmQuickpost.get('MoodRatingId').setValue(rateingsid)
           }}
         // return this.quickpostLoadDataOutputModel;
        }
        else {
        }
      },
      (error) => {
        this._toastrService.Error(error, Constant.ErrorToastrTitle);
        this.error = error;
        this.loadingScreenService.stopLoading();
      }
    );
 
}
 

  OnQuickPostTimeChange(event) {
    let timefrom = this.frmQuickpost.get('DiaryTimeFrom').value;
    let timeto = this.frmQuickpost.get('DiaryTimeTo').value;
    if (timefrom && timeto) { 
    if((timefrom.hour>=timeto.hour)){
         if(timefrom.hour>timeto.hour){  
        timefrom.hour = timeto.hour
         this.frmQuickpost.get('DiaryTimeFrom').setValue(timeto);
      }
        if(timefrom.minute>=timeto.minute)
        this.frmQuickpost.get('DiaryTimeFrom').setValue(timeto);
    }
    }
    if (timeto)
      this.spinnerTftime = timeto;
  }

  onFileSelected(event:any){
    this.uploader.uploadAll();
  }
   
  async AddItemToGallery1(_id: number, _url: string, documentname: string, documentsize: string, filetype: string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
    };
      
    this.GalleryItems1.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
    };
    const index = this.GalleryItems1.findIndex(item => item.id === _id);
    this.GalleryItems1[index] = galleryItem;
  //  console.log(this.attachmentlist)
  }


  async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
    };
      if(loadingItem.type=="Images"){
        this.newGalleryItems.push(loadingItem)
     }
    this.GalleryItems.push(loadingItem)
   // this.GalleryItems.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
    };
    const index = this.GalleryItems.findIndex(item => item.id === _id);
    this.GalleryItems[index] = galleryItem;
    //console.log(this.GalleryItems)
  }
  
  
    async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
      if (type === "Videos") {
        return new Promise<string>((resolve, reject) => {
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous'; // Enable cross-origin for video
          video.src = videoURL;
    
          video.addEventListener('loadedmetadata', () => {
            video.currentTime = 10.5; // Adjust as needed
    
            video.addEventListener('seeked', () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
    
              const ctx = canvas.getContext('2d');
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
              // Draw a play icon in the center of the canvas
              const playIconSize = 250; // Adjust the size as needed
              ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
              ctx.beginPath();
              ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
              ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
              ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
              ctx.closePath();
              ctx.fill();
    
              // Resolve with the data URL of the canvas
              resolve(canvas.toDataURL('image/png'));
    
              // Clean up
              canvas.remove();
              video.remove();
            });
    
            video.addEventListener('error', (error) => {
              resolve("assets/images/pages/Cannot_play.png")
              //  reject(`Error loading video: ${error.message}`);
            });
          });
    
          video.addEventListener('error', (error) => {
            resolve("assets/images/pages/Cannot_play.png")
            //  reject(`Error loading video metadata: ${error.message}`);
          });
    
          // Load the video
          video.load();
        });
      }
    }
  
    async generateVideoData(src): Promise<string> {
      const videoData = {
        source: [{ src: src, type: 'video/mp4' }],
        attributes: { preload: true, controls: true }
      };
      return JSON.stringify(videoData);
    }

  async onDeleteClick() {
    //console.log('onDeleteClick');
    if (!this.userQuickPosts || !this.userQuickPosts.UserQuickPostId) {
      this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
      return;
    }

    //console.log('OnAddressDeleteClick');
    Swal.fire({
      title: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TITLE_QUICKPOST_TEXT,
      text: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_TEXT,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CONFIRM_BUTTON_TEXT,
      cancelButtonText: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_CANCEL_BUTTON_TEXT,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    })
      .then(
        (async (result) => {
          if (result.value) {
            const a = await this.DeleteThisQuickPost(this.userQuickPosts.UserQuickPostId);
          }
        })
      );
  }
  async DeleteThisQuickPost(UserQuickPostId: number) {
    this.loadingScreenService.startLoading();
    this.deleteInputModel = {
      DeleteID: UserQuickPostId,
      UserDetailsID: this.authenticationService.currentUserValue.UserDetailsId
    }
    let promise = new Promise((resolve, reject) => {
      var Result: boolean;
      const path = 'api/UserQuickPost/DeleteUserQuickPost';
      let response: Observable<any>;
      response = this.globalService.PostDataById(this.deleteInputModel, path);
      response.subscribe(
        (data) => {
          this.loadingScreenService.stopLoading();
          //console.log(data);
          var result = JSON.parse(JSON.stringify(data));
          //console.log(result);
          if (result.status == Status.Success) {
            Swal.fire({
              icon: 'success',
              title: this.currentAppLanguage.data.QUICKPOST.SWEET_ALERT_DELETE_TEXT,
              text: E.ConvertToString(result.message),
              customClass: {
                confirmButton: 'btn btn-success'
              }
            });
            this.dialogRef.close(close);
            Result = true;
          }
          else {
            this._toastrService.Error('An Error Occurred – Please Contact your Administrator.', Constant.ErrorToastrTitle);
            Result = false;
          }
          resolve(Result);
        },
        (error) => {
          this._toastrService.Error(error, Constant.ErrorToastrTitle);
          this.error = this.currentAppLanguage.data.COMMON.ERROR_MESSAGE_CONNECTIONREFUSED;
          this.loadingScreenService.stopLoading();
        }
      );
    });
    return await promise;
  }

  OnClickMood(_moodid){
    this._QuickPostService.setTracker(_moodid);
// console.log(_moodid)
  }
 
   OnClickRating(id){
   this._QuickPostService.setRating(id);
  //  console.log(id)
   }

  onQuickPostChange(event) {
    this.PlaceholderQuickPost = (event == null || (event != null && event < 1)) ? this.currentAppLanguage.data.QUICKPOST.PLACEHOLDER_SELECT_POST_TYPE : "";
  }

  // onBeforeSlide = (detail: BeforeSlideDetail): void => {
  //   const { index, prevIndex } = detail;
  // };

  openDialogMapAddress() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "70%";
    dialogConfig.height = "55%";
    dialogConfig.position = { bottom: '18%' };
    
      let _model = new MapAddressOutputModel(this.userQuickPosts.Latitude, this.userQuickPosts.Longitude, this.userQuickPosts.Location);
      dialogConfig.data = new MapAddressDataModel(MapModalType.LatLong, _model);
    
    const dialogRef: MatDialogRef<MapAddressComponent> = this.dialog.open(MapAddressComponent, dialogConfig);
  
    dialogRef.componentInstance.IsDraggable = true;
    dialogRef.componentInstance.IsSaveButtonVisible = true;
    dialogRef.componentInstance.IsSearchTextVisible = true;
    dialogRef.componentInstance.IsSearchOnMap = true;
    dialogRef.afterClosed().subscribe(
      result => {
        //console.log('Map closed');
        //console.log(result);
        if (result) {
          if (E.ConvertToString(result.event) == 'SearchOnMap' && result.data) {
            
            this.userQuickPosts.Latitude = result.data.lat;
            this.userQuickPosts.Longitude = result.data.long;
            this.frmQuickpost.get('Location').setValue(E.ConvertToString(result.data.searchstring));
          }
        }
        // this.loadData();
      }
    );
  }

  onCloseButtonClick() {
    this.closeDialog();
  }
  closeDialog() {
    this.dialogRef.close(false);
  }
  GetCurrentLanguageJsonObject(currentAppLanguage) {
    var result;
    switch (currentAppLanguage) {
      case 'en':
        result = english;
        break;
      case 'in':
        result = gujarati;
        break;
      case 'hn':
        result = hindi;
        break;
    }
    return result;
  }
  get f() {
    return this.frmQuickpost.controls;
  }
}

import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarOptions, EventApi, EventClickArg, FullCalendarComponent } from '@fullcalendar/angular';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';

import { CalendarService } from 'app/main/apps/calendar/calendar.service';
import { EventRef } from 'app/main/apps/calendar/calendar.model';
import { E } from 'assets/extensions/extensions';
import { QuickPostComponent } from 'app/quick-post/quick-post/quick-post.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { QuickpostlistService } from 'app/quickpost-list/quickpostlist.service';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { Constant } from 'app/Classes/constant';
import { NoosomCategoryID, NoosomPlans } from 'app/enums';
import { TrackersComponent } from 'app/quick-post/trackers/trackers.component';
import { UploadComponent } from 'app/quick-post/upload/upload.component';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit, AfterViewInit {
  // Public
  @ViewChild('calendareventplanner') calendarComponent: FullCalendarComponent;
  public slideoutShow = false;
  public events = [];
  public event;
  public calendarRef = [];
  templcstg = JSON.parse(this._encryptDecryptService.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg')))
  plan: number;
  userCategory: number;
  public calendarOptions: CalendarOptions = {
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    initialView: 'dayGridMonth',
    initialEvents: this.events,
    weekends: true,
    editable: false,
    eventResizableFromStart: true,
    selectable: false,
    selectMirror: true,
    dayMaxEvents: 4,
    navLinks: true,
    eventClick: this.handleUpdateEventClick.bind(this),
    eventClassNames: this.eventClass.bind(this),
    select: this.handleDateSelect.bind(this),
    eventsSet: this.handleEventsSet.bind(this),
    dateClick: this.handleDateClick.bind(this)
  };
  bsConfig: Partial<BsDatepickerConfig>;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CalendarService} _calendarService
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _calendarService: CalendarService,
    private dialog: MatDialog,
    private _coreConfigService: CoreConfigService,
    private changeDetector: ChangeDetectorRef,
    private quickpostlistService: QuickpostlistService,
    private _encryptDecryptService: EncrDecrService
  ) {
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Add Event Class
   *
   * @param s
   */
  eventClass(s) {
    const calendarsColor = {
      Journal_Entry : 'primary',
      Diary_Entry : 'primary',
      Event: 'warning',
      // Personal: 'danger',
      // Family: 'warning',
      // ETC: 'info'
    };
    const colorName = calendarsColor[E.ConvertToString(s.event._def.extendedProps.calendar).replace(' ', '_')];
    return `bg-light-${colorName}`;
  }

  /**
   * Update Event
   *
   * @param eventRef
   */
  handleUpdateEventClick(eventRef: EventClickArg) {
    // console.log('eventRef');
    // console.log(eventRef);
    let _Id = parseInt(eventRef.event.id);
    //console.log(_Id);
    this.EditQuickPost(_Id);
    //this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
    this._calendarService.updateCurrentEvent(eventRef);
  }

  handleDateClick(arg) {
    alert('Date clicked: ' + arg.dateStr);
  }



  /**
   * Toggle the sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /**
   * Date select Event
   *
   * @param eventRef
   */
  handleDateSelect(eventRef) {
    const newEvent = new EventRef();
    newEvent.start = eventRef.start;
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
    this._calendarService.onCurrentEventChange.next(newEvent);
  }

  handleEventsSet(events: EventApi[]) {
    // console.log('handleEventsSet');
    // console.log(events);
    this.changeDetector.detectChanges();
  }

  EditQuickPost(userQuickPostID: number) {
    if ((this.plan == NoosomPlans.Premium_1)||(this.userCategory == NoosomCategoryID.Test))
      this.openDialog(UploadComponent, userQuickPostID)
    else if(this.plan == NoosomPlans.Noosom_Free_Forever)
    this.openDialog(QuickPostComponent, userQuickPostID);
  }

  openDialog(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = this.isMobileScreen() ? '100%' : '66%';
    //dialogConfig.height = "80%";
   // dialogConfig.position = { left: '23%', bottom: '8%' };
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        // console.log('data');
        // console.log(data);
        if (data) {
          this._calendarService.getEvents();
          //this.loadQuickPostsdata();
        }
      }
    );
  }
  private isMobileScreen(): boolean {
    return window.innerWidth <= 767; // You can adjust this value as per your requirements
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe config change
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      // ! If we have zoomIn route Transition then load calendar after 450ms(Transition will finish in 400ms)
      // if (config.layout.animation === 'zoomIn') {
      //   setTimeout(() => {
      //     this._calendarService.onEventChange.subscribe(res => {
      //       this.events = res;
      //       this.calendarOptions.events = res;
      //     });
      //   }, 450);
      // } else {
      // Subscribe to Event Change
      this.loadQuickPostsdata();
      this.bsConfig = {
        containerClass: 'theme-default',
        dateInputFormat: 'YYYY-MM-DD'
      };
      // this._calendarService.onEventChange.subscribe(res => {
      //   //console.log('res');
      //   //console.log(res);
      //   this.events = res;
      //   this.calendarOptions.events = res;
      // });
      //}
    });

    if(this.templcstg){
      this.plan = this.templcstg.NoosomPlan
     this.userCategory = this.templcstg.userCategory}

    this._calendarService.onCurrentEventChange.subscribe(res => {
      //console.log(res);
      this.event = res;
    });
    this._calendarService.onCalendarChange.subscribe(res => {
      this.calendarRef = res;
    });
  }

  ngAfterViewChecked(): void {
    if (this.quickpostlistService.getGridRefresh()) {
      this._calendarService.getEvents();
      this.quickpostlistService.setGridRefresh(false)
    }
  }

  loadQuickPostsdata() {
    //console.log('loadQuickPostsdata');
    this._calendarService.onEventChange.subscribe(res => {
      // console.log('calendarComponent');
      // console.log(this.calendarComponent);
      //this.events = [];
      this.events = res;
      // this.calendarOptions.events = [];
      //this.calendarOptions.events = res;
      this.calendarOptions = { ...this.calendarOptions, events: this.events };
      //this.calendarOptions.events = [...this.events];
    });
  }

  /**
   * Calendar's custom button on click toggle sidebar
   */
  ngAfterViewInit() {
    // Store this to _this as we need it on click event to call toggleSidebar
    let _this = this;
    this.calendarOptions.customButtons = {
      sidebarToggle: {
        text: '',
        click() {
          _this.toggleSidebar('calendar-main-sidebar');
        }
      }
    };
  }
}

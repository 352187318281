import { PostConstruct } from "ag-grid-community";
import { Status } from "app/enums";
export const locale = {
  lang: 'en',
  data: {
    COMMON: {
      CONTROL_LABEL_EMAIL_ADDRESS: 'Email Address',
      PLACEHOLDER_EMAIL_ADDRESS: 'Type here...',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_MOBILE_NUMBER: '0123456789',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      ERROR_MESSAGE_REQUIRED_EMAIL_ADDRESS: 'The email is required!',
      ERROR_MESSAGE_INVALID_EMAIL: 'Please enter your email address in format:\nyourname@example.com',
      ERROR_MESSAGE_CONNECTIONREFUSED: 'Could not connect with server.',
      TOOLTIP_TEXT_BUTTON_CLOSE: 'Close',
      COMMON_STATUS:
        [
          { ErrorCode: Status.UserNotFound, ErrorMessage: 'User could not be found.' }
        ],
    },
    QUICKPOST: {
      HEADING_LABEL_ADD_A_QUICKPOST: 'Add a ',
      HEADING_LABEL_EDIT_A_QUICKPOST: 'Edit a ',
      HEADING_TEXT: 'Entry',
      INFO_TEXT: 'Add all your Diary / Journal Details',
      INFO_TEXT3: 'Select your Mood and Rate the Moment here',
      INFO_TEXT1: 'Select some Images and Videos. You can upload them one at a time or select Upload All at once',
      INFO_TEXT2: 'All your uploaded Images & Videos will be displayed here',
      INFO_TEXT4:'Paperback view is designed to look like a hand written diary, no images, just the post date, time, location and your own notes.',
      TAB_HEADING_QUICKPOST: 'Quick Post',
      TAB_HEADING_TRACKERS: 'Trackers',
      TAB_HEADING_UPLOAD: 'Upload',
      TAB_HEADING_IMAGES_AND_VIDEOS: 'Images & Videos',
      CONTROL_LABEL_TITLE: 'Title',
      CONTROL_LABEL_DIARY_DATE: 'Date',
      CONTROL_LABEL_DIARY_TIME_FROM: 'Time From',
      CONTROL_LABEL_DIARY_TIME_TO: 'Time To',
      CONTROL_LABEL_TO_DATE:'To Date',
      CONTROL_LABEL_FROM_DATE:'From Date',
      CONTROL_LABEL_MONTH:'Month',
      CONTROL_LABEL_DATE_RANGE:'Date Range',
      CONTROL_LABEL_SEARCH:'Search',
      CONTROL_LABEL_LOCATION: 'Location',
      CONTROL_LABEL_POST_TYPE: 'Post Type',
      CONTROL_LABEL_EVENT: 'Event',
      CONTROL_LABEL_TAG_CATEGORY: 'Tag Category',
      CONTROL_LABEL_POST_CONTENT: 'Post Content',
      PLACEHOLDER_DATE: 'dd/mm/yyyy',
      PLACEHOLDER_SEARCH_LOCATION_HERE: 'Search Location Here',
      PLACEHOLDER_SELECT_POST_TYPE: 'Select Post Type',
      PLACEHOLDER_SELECT_EVENT: 'Select Event',
      PLACEHOLDER_ADD_TAG: 'Add Tag',
      PLACEHOLDER_TYPE_HERE: 'Type here...',
      PLACEHOLDER_ADD_SELECT_TAG_HERE: 'Add/Select Tag Here...',
      HEADING_TEXT_DIARY: 'Diary',
      HEADING_TEXT_JOURNAL: 'Journal',
      HEADING_TEXT_PAPERBACKVIEW:'Paperback View',
      SWEET_ALERT_TEXT: "You won't be able to undo this!",
      SWEET_ALERT_CONFIRM_BUTTON_TEXT: 'Yes, delete it!',
      SWEET_ALERT_CANCEL_BUTTON_TEXT: 'Cancel',
      SWEET_ALERT_TEXT_DELETED: 'Deleted!',
      SWEET_ALERT_CONFIRM_BUTTON_TEXT_OKAY: 'OK',
      SWEET_ALERT_TITLE_QUICKPOST_TEXT: 'Are you sure, you want to delete this Quick Post and all the attachments associated with it?',
      SWEET_ALERT_TITLE_QUICKPOST_IMAGE: 'Are you sure, you want to delete this Quick Post Document?',
      SWEET_ALERT_DELETE_TEXT: 'Deleted!',
      TOOLTIP_TEXT_BUTTON_CALENDAR: 'Calendar',
      TOOLTIP_TEXT_BUTTON_EDIT:'Edit',
      TOOLTIP_TEXT_BUTTON_LOCATION: 'Location',
      TOOLTIP_TEXT_BUTTON_SEARCH_ON_MAP: 'Search on Map',
      TOOLTIP_TEXT_ADD_A_QUICKPOST: 'Add a ',
      TOOLTIP_TEXT_TEXT_STYLE: 'Text Style',
      TOOLTIP_TEXT_TEXT_SIZE: 'Text Size',
      TOOLTIP_TEXT_TEXT_COLOUR: 'Text Colour',
      TOOLTIP_TEXT_TEXT_ALIGNMENT: 'Text Alignment',
      TOOLTIP_TEXT_BOLD: 'Bold',
      TOOLTIP_TEXT_ITALIC: 'Italic',
      TOOLTIP_TEXT_UNDERLINE: 'Underline',
      TOOLTIP_TEXT_LINK: 'Link',
      TOOLTIP_TEXT_BULLET_LIST: 'Bullet List',
      TOOLTIP_TEXT_NUMBERED_LIST: 'Numbered List',
      TOOLTIP_TEXT_CLEAR_FORMAT: 'Clear Format',
      TOOLTIP_TEXT_DIARY_DESCRIPTION: 'A short description or headline of your Diary / Journal Entry',
      TOOLTIP_TEXT_DIARY_DATE_CREATED: 'This field is the date the Diary Entry was created',
      TOOLTIP_TEXT_DIARY_START_TIME: 'This field shows the start time of the Diary Entry that was created',
      TOOLTIP_TEXT_DIARY_END_TIME: 'This field shows the end time of the Diary Entry that was created',
      TOOLTIP_TEXT_SEARCH_MAP_BUTTON: 'Click the Search on Map button to search & select the location of your Diary Entry. You will be able to adjust the location using the pin',
      TOOLTIP_TEXT_DEFAULT_PRIVACY_SETTING: 'Private will always be default. You will have the ability to share to friends, family or public once you upgrade',
      TOOLTIP_TEXT_DIARY_EVENT_LINKING: 'You can link your diary posts to specific events such as when you are travelling, having a dinner with friends or family etc…',
      TOOLTIP_TEXT_TAG_CODE_CATEGORY: 'The Tag Code Category is your own unique choice of words that you can use to search the Noosom Web System',
      TOOLTIP_TEXT_DIARY_DESCRIPTION_EDITOR: 'Enter the description of your Diary / Journal Entry using the editor',
      BUTTON_TEXT_SEARCH_ON_MAP: 'Search on Map',
      BUTTON_TEXT_ADD_A_QUICKPOST: 'Add a ',
      BUTTON_TEXT_SAVE_CHANGES: 'Save Changes',
      BUTTON_TEXT_DELETE: 'Delete',
      BUTTON_TEXT_CANCEL: 'Cancel',
      BUTTON_TEXT_SAVE_AND_CLOSE: 'Save and Close',
      BUTTON_TEXT_SAVE_AND_NEXT: 'Save and Next',
      BUTTON_TEXT_ABANDON: 'Abandon',
      TOAST_MESSAGE_QUICKPOST_ADDED_SUCCESSFULLY: 'Quick Post added successfully.',
      TOAST_MESSAGE_QUICKPOST_UPDATED_SUCCESSFULLY: 'Quick Post updated successfully.',
      WARNING_MESSAGE_SELECT_LOCATION: 'Please select location on a map.',
      ERROR_MESSAGE_REQUIRED_TITLE: 'The title is required!',
      ERROR_MESSAGE_REQUIRED_DIARY_DATE: 'The diary date is required!',
      ERROR_MESSAGE_REQUIRED_DIARY_TIME: 'Diary time from is required!',
      ERROR_MESSAGE_REQUIRED_DIARY_TIME_TO: 'Diary time to is required!',
      ERROR_MESSAGE_REQUIRED_LOCATION: 'The location is required!',
      ERROR_MESSAGE_REQUIRED_POST_TYPE: 'The post type is required!',
      ERROR_MESSAGE_REQUIRED_POST_CONTENT: 'The post content is required!',
      ERROR_MESSAGE_MAX_LENGTH_100: 'You have typed too many characters, maximum allowed is 100',
      ERROR_MESSAGE_MAX_LENGTH_1000: 'You have typed too many characters, maximum allowed is 1000',

    },
    TRACKERS: {
      HOW_IS_YOUR_MOOD_TEXT: 'How is your mood at this moment?',
      HOW_WOULD_YOU_RATE_TEXT: 'How would you rate this moment?',
      MOOD_TEXT_HAPPY: 'Happy',
      MOOD_TEXT_SAD: 'Sad',
      MOOD_TEXT_ANGRY: 'Angry',
      MOOD_TEXT_CRYING: 'Crying',
      MOOD_TEXT_MEH: 'Meh',
      MOOD_TEXT_EXCITED: 'Excited',
      MOOD_TEXT_SLEEPY: 'Sleepy',
      MOOD_TEXT_LONELY: 'Lonely',
    },
    UPLOAD: {
      BUTTON_TEXT_UPLOAD_ALL: 'Upload All',
      BUTTON_TEXT_PAUSE_ALL: 'Pause All',
      BUTTON_TEXT_DOWNLOAD_ALL: 'Download All',
      BUTTON_TEXT_REMOVE_ALL: 'Remove All',
      CONTROL_LABEL_MULTIPLE: 'Multiple',
      COLUMN_HEADING_NAME: 'NAME',
      COLUMN_HEADING_SIZE: 'SIZE',
      COLUMN_HEADING_PROGRESS: 'PROGRESS',
      COLUMN_HEADING_STATUS: 'STATUS',
      COLUMN_HEADING_DATE_CREATED: 'DATE CREATED',
      COLUMN_HEADING_TYPE: 'TYPE',
      COLUMN_HEADING_ACTIONS: 'ACTIONS',
      HEADING_TEXT_SELECT_IMAGES: 'Select Images & Videos',
      HEADING_TEXT_UPLOAD_QUEUE: 'Upload Queue',
      HEADING_TEXT_QUEUE_PROGRESS: 'Queue Progress',
      PLACEHOLDER_CHOOSE_FILES: 'Choose Files',
      TEXT_FILE_TYPES: 'Allowed file types:',
      TEXT_QUEUE_LENGTH: 'Queue Length:',
      TEXT_MB: 'MB',
    },
    TODO:{
      LABEL_ADD_TASK:'Add Task',
      LABEL_ADD_A_TASK:'Add a Task',
      LABEL_TITLE:'Title',
      LABEL_DUE_DATE:'Due Date',
      LABEL_DUE_TIME:'Due Time',
      LABEL_PRIORITY:'Priority',
      LABEL_EDIT_TASK:'Edit Task',
      LABEL_DESCRIPTION:'Description',
      LABEL_BUTTON_COMPLETE:'Complete',
      LABEL_BUTTON_MARK_COMPLETE:'Mark Complete',
      LABEL_BUTTON_MARK_INCOMPLETE:'Mark Pending',
      LABEL_BUTTON_MARK_IMPORTANT:'Mark Important',
      LABEL_BUTTON_MARK_UNIMPORTANT:'Mark Unimportant',
      ERROR_MESSAGE_TITLE_REQUIRED:'The title is required!',
      ERROR_MESSAGE_PRIORITY_REQUIRED:'The priority is required!',
      ERROR_MESSAGE_DUE_TIME_REQUIRED:'The due time is required!',
      ERROR_MESSAGE_DUE_DATE_REQUIRED:'The due date is required!',
      ERROR_MESSAGE_DESCRIPTION_REQUIRED:'The description is required!',
      ERROR_MESSAGE_MAX_50:'You have typed too many characters, maximum allowed is 50'
    }
  }
};

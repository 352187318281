import { CoreMenu } from '@core/types';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { NoosomPlans, NoosomCategoryID } from 'app/enums';
const encrDecrService: EncrDecrService = new EncrDecrService();
var templcstg = JSON.parse(encrDecrService.get(Constant.EncrDecrKey, localStorage.getItem('lcstrg')))
if (templcstg) {
  var plan = templcstg?.NoosomPlan;
  var userCategory = templcstg?.userCategory
}
// var plan:number=parseInt(encrDecrService.get(Constant.EncrDecrKey,localStorage.getItem('NoosomPlan')))
// var userCategory:number=parseInt(encrDecrService.get(Constant.EncrDecrKey,localStorage.getItem('userCategory')))
//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface
export const menu: CoreMenu[] = [
  //Dashboard
  // {
  //   id: 'dashboard',
  //   title: 'Dashboard',
  //   translate: 'MENU.DASHBOARD.COLLAPSIBLE',
  //   type: 'collapsible',
  //   // role: ['Admin'], //? To hide collapsible based on user role
  //   icon: 'home',
  //   badge: {
  //     title: '2',
  //     translate: 'MENU.DASHBOARD.BADGE',
  //     classes: 'badge-light-warning badge-pill'
  //   },
  //   children: [
  //     {
  //       id: 'analytics',
  //       title: 'Analytics',
  //       translate: 'MENU.DASHBOARD.ANALYTICS',
  //       type: 'item',
  //       role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
  //       icon: 'circle',
  //       url: 'dashboard/analytics'
  //     },
  //     {
  //       // If role is not assigned will be display to all
  //       id: 'ecommerce',
  //       title: 'eCommerce',
  //       translate: 'MENU.DASHBOARD.ECOMMERCE',
  //       type: 'item',
  //       icon: 'circle',
  //       url: 'dashboard/ecommerce'
  //     }
  //   ]
  // }



  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.APPS.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'about-me',
    title: 'About Me',
    translate: 'MENU.APPS.ABOUT_ME',
    type: 'item',
    icon: 'user',
    url: 'about-me'
  },
  {
    id: 'My-diary-planner',
    title: 'My Diary Planner',
    translate: templcstg?.DiaryOrJournal ? templcstg?.DiaryOrJournal == 2 ? 'MENU.APPS.HEADING_TEXT_JOURNAL'
      : 'MENU.APPS.HEADING_TEXT_DIARY' : encrDecrService.get(Constant.EncrDecrKey, sessionStorage.getItem('DiaryJournal')) == '2' ? 'MENU.APPS.HEADING_TEXT_JOURNAL'
      : 'MENU.APPS.HEADING_TEXT_DIARY',
    type: 'collapsible',
    icon: 'book-open',
    children: [
      {
        id: 'Calendar View',
        title: 'Calendar View',
        translate: 'MENU.APPS.CALENDAR_VIEW',
        type: 'item',
        icon: 'circle',
        url: 'My-diary-planner',
      },
      {
        id: 'All Diary Entries',
        title: 'All Diary Entries',
        translate: templcstg?.DiaryOrJournal ? templcstg?.DiaryOrJournal == 2 ? 'MENU.APPS.ALL_JOURNAL_ENTRIES'
          : 'MENU.APPS.ALL_DIARY_ENTRIES' : encrDecrService.get(Constant.EncrDecrKey, sessionStorage.getItem('DiaryJournal')) == '2' ? 'MENU.APPS.ALL_JOURNAL_ENTRIES'
          : 'MENU.APPS.ALL_DIARY_ENTRIES',
        type: 'item',
        icon: 'circle',
        url: 'quickpost-list'
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'Diary View' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'Diary View' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? templcstg?.DiaryOrJournal ? templcstg?.DiaryOrJournal == 2 ? 'MENU.APPS.JOURNAL_VIEW'
          : 'MENU.APPS.DIARY_VIEW' : encrDecrService.get(Constant.EncrDecrKey, sessionStorage.getItem('DiaryJournal')) == '2' ? 'MENU.APPS.JOURNAL_VIEW'
          : 'MENU.APPS.DIARY_VIEW' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'diary-view' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'paperback-view' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'Paperback View' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.PAPERBACK_VIEW' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'paperback' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'todo' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'To-Do' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.TODO' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'apps/todo/all' : null
      },
    ]
  },
  {
    id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'map-view' : null,
    title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Life Journey on Map' : null,
    translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.MY_JOURNEY_ON_MAP' : null,
    // title: 'Import Data',
    type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
    icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'tabler-map-2' : null,
    url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'map-view' : null
  },
  {
    id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Pastimes & Hobbies' : null,
    title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Pastimes & Hobbies' : null,
    translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.MY_PASTIMES' : null,
    type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
    icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'heart' : null,
    url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mypastimes' : null
  },
  {
    id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'my-blog' : null,
    title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Blog' : null,
    translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.MY_BLOG' : null,
    type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
    icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'globe' : null,
    url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'blog' : null
  },
  {
    id: 'my-full-diary',
    title: 'My Image Gallery',
    translate: 'MENU.APPS.MY_IMAGE_GALLERY',
    // title: 'My Full Diary',
    type: 'collapsible',
    icon: 'image',
    children: [
      {
        id: 'my-full-diary',
        title: 'My Image Gallery',
        translate: 'MENU.APPS.MY_IMAGE_GALLERY',
        type: 'item',
        icon: 'circle',
        url: 'my-image-gallery',
      },
      {
        id: 'filemanager',
        title: 'File Manager',
        translate: 'MENU.APPS.FILEMANAGER',
        type: 'item',
        icon: 'circle',
        url: 'filemanager'
      },
    ],
  },
  {
    id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My life Story' : null,
    title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My life Story' : null,
    translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.MY_LIFE_STORY' : null,
    type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
    icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'tabler-notebook' : null,
    url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylifestory' : null
  },
  {
    id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'Me on the Web' : null,
    title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'Me on the Web' : null,
    translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.ME_ON_WEB' : null,
    type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
    icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'tabler-globe' : null,
    url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'me-on-web' : null
  },
  {
    id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Lists' : null,
    title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Lists' : null,
    translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.HEADING_MY_LISTS' : null,
    type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'collapsible' : null,
    icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'list' : null,
    children: [
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'list view' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'Journal Entry' : null,
        translate: templcstg?.DiaryOrJournal ? templcstg?.DiaryOrJournal == 2 ? 'MENU.APPS.ALL_JOURNAL_ENTRIES'
          : 'MENU.APPS.ALL_DIARY_ENTRIES' : encrDecrService.get(Constant.EncrDecrKey, sessionStorage.getItem('DiaryJournal')) == '2' ? 'MENU.APPS.ALL_JOURNAL_ENTRIES'
          : 'MENU.APPS.ALL_DIARY_ENTRIES',
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/diaryEntries' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Awards' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Awards' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.HEADING_MY_AWARDS' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/awards' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Achievement' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Achievement' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.HEADING_MY_ACHIEVEMENT' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/achievement' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Jobs' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Jobs' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.HEADING_MY_JOBS' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/jobs' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Families' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Families' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.HEADING_MY_FAMILIES' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/families' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Friends' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Friends' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.HEADING_MY_FRIENDS' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/friends' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Pastimes & Hobbies' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Pastimes & Hobbies' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.MY_PASTIMES' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/pastime' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'Me on the Web' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'Me on the Web' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.ME_ON_WEB' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/meonweb' : null
      },
      {
        id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Legals' : null,
        title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Legals' : null,
        translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.MY_LEGALS' : null,
        type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
        icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'circle' : null,
        url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'mylist/legals' : null
      }
    ]
  },
  {
    id: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Legals' : null,
    title: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'My Legals' : null,
    translate: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'MENU.APPS.MY_LEGALS' : null,
    type: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'item' : null,
    icon: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'briefcase' : null,
    url: (plan == NoosomPlans.Premium_1 || userCategory == NoosomCategoryID.Test) ? 'legals' : null
  },
  {
    id: 'Utilities',
    title: 'Utilities',
    translate: 'MENU.APPS.UTILITIES',
    // title: 'Import Data',
    type: 'item',
    icon: 'tool',
    url: 'Utilities'
  },
  {
    id: 'premium-1',
    title: 'Premium-1 Demo',
    translate: 'MENU.APPS.PREMIUM',
    // externalUrl:true,
    openInNewTab: true,
    // title: 'Import Data',
    type: 'item',
    icon: 'film',
    url: 'experiments/view'
    // url: 'https://noosom.com/interactive-demo-premium-1/'
  },











  // Apps & Pages

  // {
  //   id: 'apps',
  //   type: 'section',
  //   title: 'Apps & Pages',
  //   translate: 'MENU.APPS.SECTION',
  //   icon: 'package',
  //   children: [
  //     {
  //       id: 'email',
  //       title: 'Email',
  //       translate: 'MENU.APPS.EMAIL',
  //       type: 'item',
  //       icon: 'mail',
  //       url: 'apps/email'
  //     },
  //     {
  //       id: 'chat',
  //       title: 'Chat',
  //       translate: 'MENU.APPS.CHAT',
  //       type: 'item',
  //       icon: 'message-square',
  //       url: 'apps/chat'
  //     },
  //     {
  //       id: 'todo',
  //       title: 'Todo',
  //       translate: 'MENU.APPS.TODO',
  //       type: 'item',
  //       icon: 'check-square',
  //       url: 'apps/todo'
  //     },
  //     {
  //       id: 'calendar',
  //       title: 'Calendar',
  //       translate: 'MENU.APPS.CALENDAR',
  //       type: 'item',
  //       icon: 'calendar',
  //       url: 'apps/calendar'
  //     },
  //     {
  //       id: 'pages',
  //       title: 'Pages',
  //       translate: 'MENU.PAGES.SECTION',
  //       type: 'collapsible',
  //       icon: 'file-text',
  //       children: [
  //         {
  //           id: 'authentication',
  //           title: 'Authentication',
  //           translate: 'MENU.PAGES.AUTH.COLLAPSIBLE',
  //           type: 'collapsible',
  //           icon: 'circle',
  //           children: [
  //             {
  //               id: 'login-v1',
  //               title: 'Login V1',
  //               translate: 'MENU.PAGES.AUTH.LOGIN1',
  //               type: 'item',
  //               url: 'pages/authentication/login-v1',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'login-v2',
  //               title: 'Login V2',
  //               translate: 'MENU.PAGES.AUTH.LOGIN2',
  //               type: 'item',
  //               url: 'pages/authentication/login-v2',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'register-v1',
  //               title: 'Register V1',
  //               translate: 'MENU.PAGES.AUTH.REGISTER1',
  //               type: 'item',
  //               url: 'pages/authentication/register-v1',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'register-v2',
  //               title: 'Register V2',
  //               translate: 'MENU.PAGES.AUTH.REGISTER2',
  //               type: 'item',
  //               url: 'pages/authentication/register-v2',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'forgot-password-v1',
  //               title: 'Forgot Password V1',
  //               translate: 'MENU.PAGES.AUTH.FORGOTPASSWORD1',
  //               type: 'item',
  //               url: 'pages/authentication/forgot-password-v1',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'forgot-password-v2',
  //               title: 'Forgot Password V2',
  //               translate: 'MENU.PAGES.AUTH.FORGOTPASSWORD2',
  //               type: 'item',
  //               url: 'pages/authentication/forgot-password-v2',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'reset-password-v1',
  //               title: 'Reset Password V1',
  //               translate: 'MENU.PAGES.AUTH.RESETPASSWORD1',
  //               type: 'item',
  //               url: 'pages/authentication/reset-password-v1',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'reset-password-v2',
  //               title: 'Reset Password V2',
  //               translate: 'MENU.PAGES.AUTH.RESETPASSWORD2',
  //               type: 'item',
  //               url: 'pages/authentication/reset-password-v2',
  //               openInNewTab: true
  //             }
  //           ]
  //         },
  //         {
  //           id: 'account-settings',
  //           title: 'Account Settings',
  //           translate: 'MENU.PAGES.ACCOUNTSETTINGS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'pages/account-settings'
  //         },
  //         {
  //           id: 'profile',
  //           title: 'Profile',
  //           translate: 'MENU.PAGES.PROFILE',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'pages/profile'
  //           // collapsed: true
  //         },
  //         {
  //           id: 'faq',
  //           title: 'FAQ',
  //           translate: 'MENU.PAGES.FAQ',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'pages/faq'
  //         },
  //         {
  //           id: 'knowledgeBase',
  //           title: 'Knowledge Base',
  //           translate: 'MENU.PAGES.KB',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'pages/knowledge-base'
  //         },
  //         {
  //           id: 'pricing',
  //           title: 'Pricing',
  //           translate: 'MENU.PAGES.PRICING',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'pages/pricing'
  //         },

  //         {
  //           id: 'blog',
  //           title: 'Blog',
  //           translate: 'MENU.PAGES.BLOG.COLLAPSIBLE',
  //           type: 'collapsible',
  //           icon: 'circle',
  //           children: [
  //             {
  //               id: 'blog-list',
  //               title: 'List',
  //               translate: 'MENU.PAGES.BLOG.LIST',
  //               type: 'item',
  //               url: 'pages/blog-list'
  //             },
  //             {
  //               id: 'blog-details',
  //               title: 'Detail',
  //               translate: 'MENU.PAGES.BLOG.DETAILS',
  //               type: 'item',
  //               url: 'pages/blog-details'
  //             },
  //             {
  //               id: 'blog-edit',
  //               title: 'Edit',
  //               translate: 'MENU.PAGES.BLOG.EDIT',
  //               type: 'item',
  //               url: 'pages/blog-edit'
  //             }
  //           ]
  //         },

  //         {
  //           id: 'mail-template',
  //           title: 'Mail Template',
  //           translate: 'MENU.PAGES.MAIL.COLLAPSIBLE',
  //           type: 'collapsible',
  //           icon: 'circle',
  //           children: [
  //             {
  //               id: 'mail-welcome',
  //               title: 'Welcome',
  //               translate: 'MENU.PAGES.MAIL.WELCOME',
  //               type: 'item',
  //               url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
  //               externalUrl: true,
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'mail-reset',
  //               title: 'Reset Password',
  //               translate: 'MENU.PAGES.MAIL.RESET',
  //               type: 'item',
  //               url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
  //               externalUrl: true,
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'mail-verify',
  //               title: 'Verify',
  //               translate: 'MENU.PAGES.MAIL.VERIFY',
  //               type: 'item',
  //               url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
  //               externalUrl: true,
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'mail-deactivate',
  //               title: 'Deactivate',
  //               translate: 'MENU.PAGES.MAIL.DEACTIVATE',
  //               type: 'item',
  //               url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
  //               externalUrl: true,
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'mail-invoice',
  //               title: 'Invoice',
  //               translate: 'MENU.PAGES.MAIL.INVOICE',
  //               type: 'item',
  //               url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
  //               externalUrl: true,
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'mail-promotional',
  //               title: 'Promotional',
  //               translate: 'MENU.PAGES.MAIL.PROMOTIONAL',
  //               type: 'item',
  //               url: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
  //               externalUrl: true,
  //               openInNewTab: true
  //             }
  //           ]
  //         },

  //         {
  //           id: 'miscellaneous',
  //           title: 'Miscellaneous',
  //           translate: 'MENU.PAGES.MISC.COLLAPSIBLE',
  //           type: 'collapsible',
  //           icon: 'circle',
  //           children: [
  //             {
  //               id: 'misc-comingsoon',
  //               title: 'Coming Soon',
  //               translate: 'MENU.PAGES.MISC.COMINGSOON',
  //               type: 'item',
  //               url: 'pages/miscellaneous/coming-soon',
  //               openInNewTab: true
  //             },

  //             {
  //               id: 'misc-not-authorized',
  //               title: 'Not Authorized',
  //               translate: 'MENU.PAGES.MISC.NOTAUTH',
  //               type: 'item',
  //               url: 'pages/miscellaneous/not-authorized',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'maintenance',
  //               title: 'Maintenance',
  //               translate: 'MENU.PAGES.MISC.MAINTENANCE',
  //               type: 'item',
  //               url: 'pages/miscellaneous/maintenance',
  //               openInNewTab: true
  //             },
  //             {
  //               id: 'error',
  //               title: 'Error',
  //               translate: 'MENU.PAGES.MISC.ERROR',
  //               type: 'item',
  //               url: 'pages/miscellaneous/error',
  //               openInNewTab: true
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: 'invoice',
  //       title: 'Invoice',
  //       translate: 'MENU.APPS.INVOICE.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'file-text',
  //       children: [
  //         {
  //           id: 'invoice-list',
  //           title: 'List',
  //           translate: 'MENU.APPS.INVOICE.LIST',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/invoice/list'
  //         },
  //         {
  //           id: 'invoicePreview',
  //           title: 'Preview',
  //           translate: 'MENU.APPS.INVOICE.PREVIEW',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/invoice/preview'
  //         },
  //         {
  //           id: 'invoiceEdit',
  //           title: 'Edit',
  //           translate: 'MENU.APPS.INVOICE.EDIT',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/invoice/edit'
  //         },
  //         {
  //           id: 'invoiceAdd',
  //           title: 'Add',
  //           translate: 'MENU.APPS.INVOICE.ADD',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/invoice/add'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'e-commerce',
  //       title: 'eCommerce',
  //       translate: 'MENU.APPS.ECOMMERCE.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'shopping-cart',
  //       children: [
  //         {
  //           id: 'shop',
  //           title: 'Shop',
  //           translate: 'MENU.APPS.ECOMMERCE.SHOP',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/e-commerce/shop'
  //         },
  //         {
  //           id: 'details',
  //           title: 'Details',
  //           translate: 'MENU.APPS.ECOMMERCE.DETAIL',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/e-commerce/details'
  //         },
  //         {
  //           id: 'wishList',
  //           title: 'Wish List',
  //           translate: 'MENU.APPS.ECOMMERCE.WISHLIST',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/e-commerce/wishlist'
  //         },
  //         {
  //           id: 'checkout',
  //           title: 'Checkout',
  //           translate: 'MENU.APPS.ECOMMERCE.CHECKOUT',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/e-commerce/checkout'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'users',
  //       title: 'User',
  //       translate: 'MENU.APPS.USER.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'user',
  //       children: [
  //         {
  //           id: 'list',
  //           title: 'List',
  //           translate: 'MENU.APPS.USER.LIST',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/user/user-list'
  //         },
  //         {
  //           id: 'view',
  //           title: 'View',
  //           translate: 'MENU.APPS.USER.VIEW',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/user/user-view'
  //         },
  //         {
  //           id: 'edit',
  //           title: 'Edit',
  //           translate: 'MENU.APPS.USER.EDIT',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'apps/user/user-edit'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // User Interface
  // {
  //   id: 'user-interface',
  //   type: 'section',
  //   title: 'User Interface',
  //   translate: 'MENU.UI.SECTION',
  //   icon: 'layers',
  //   children: [
  //     {
  //       id: 'typography',
  //       title: 'Typography',
  //       translate: 'MENU.UI.TYPOGRAPHY',
  //       type: 'item',
  //       icon: 'type',
  //       url: 'ui/content/typography'
  //     },
  //     {
  //       id: 'colors',
  //       title: 'Colors',
  //       translate: 'MENU.UI.COLORS',
  //       type: 'item',
  //       icon: 'droplet',
  //       url: 'ui/colors'
  //     },
  //     {
  //       id: 'feather',
  //       title: 'Feather',
  //       translate: 'MENU.UI.FEATHER',
  //       type: 'item',
  //       icon: 'eye',
  //       url: 'ui/icons/feather'
  //     },
  //     {
  //       id: 'cards',
  //       title: 'Cards',
  //       translate: 'MENU.UI.CARDS.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'credit-card',
  //       badge: {
  //         title: 'New',
  //         translate: 'MENU.UI.CARDS.BADGE',
  //         classes: 'badge-light-success badge-pill'
  //       },
  //       children: [
  //         {
  //           id: 'card-basic',
  //           title: 'Basic',
  //           translate: 'MENU.UI.CARDS.BASIC',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'ui/card/card-basic'
  //         },
  //         {
  //           id: 'card-advance',
  //           title: 'Advance',
  //           translate: 'MENU.UI.CARDS.ADVANCE',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'ui/card/advance'
  //         },
  //         {
  //           id: 'card-statistics',
  //           title: 'Statistics',
  //           translate: 'MENU.UI.CARDS.STATISTICS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'ui/card/statistics'
  //         },
  //         {
  //           id: 'Card-analytics',
  //           title: 'Analytics',
  //           translate: 'MENU.UI.CARDS.ANALYTICS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'ui/card/analytics'
  //         },
  //         {
  //           id: 'card-actions',
  //           title: 'Actions',
  //           translate: 'MENU.UI.CARDS.ACTIONS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'ui/card/actions'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'components',
  //       title: 'Components',
  //       translate: 'MENU.UI.COMPONENTS.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'archive',
  //       children: [
  //         {
  //           id: 'components-alerts',
  //           title: 'Alerts',
  //           translate: 'MENU.UI.COMPONENTS.ALERTS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/alerts'
  //         },
  //         {
  //           id: 'components-avatar',
  //           title: 'Avatar',
  //           translate: 'MENU.UI.COMPONENTS.AVATAR',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/avatar'
  //         },
  //         {
  //           id: 'components-badges',
  //           title: 'Badges',
  //           translate: 'MENU.UI.COMPONENTS.BADGES',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/badges'
  //         },
  //         {
  //           id: 'components-breadcrumbs',
  //           title: 'Breadcrumbs',
  //           translate: 'MENU.UI.COMPONENTS.BREADCRUMBS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/breadcrumbs'
  //         },
  //         {
  //           id: 'components-buttons',
  //           title: 'Buttons',
  //           translate: 'MENU.UI.COMPONENTS.BUTTONS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/buttons'
  //         },
  //         {
  //           id: 'components-carousel',
  //           title: 'Carousel',
  //           translate: 'MENU.UI.COMPONENTS.CAROUSEL',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/carousel'
  //         },
  //         {
  //           id: 'components-collapse',
  //           title: 'Collapse',
  //           translate: 'MENU.UI.COMPONENTS.COLLAPSE',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/collapse'
  //         },
  //         {
  //           id: 'components-divider',
  //           title: 'Divider',
  //           translate: 'MENU.UI.COMPONENTS.DIVIDER',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/divider'
  //         },
  //         {
  //           id: 'components-drop-downs',
  //           title: 'Dropdowns',
  //           translate: 'MENU.UI.COMPONENTS.DROPDOWNS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/dropdowns'
  //         },
  //         {
  //           id: 'components-list-group',
  //           title: 'List Group',
  //           translate: 'MENU.UI.COMPONENTS.GROUP',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/list-group'
  //         },
  //         {
  //           id: 'components-media-objects',
  //           title: 'Media Objects',
  //           translate: 'MENU.UI.COMPONENTS.OBJECTS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/media-objects'
  //         },
  //         {
  //           id: 'components-modals',
  //           title: 'Modals',
  //           translate: 'MENU.UI.COMPONENTS.MODALS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/modals'
  //         },
  //         {
  //           id: 'components-navs',
  //           title: 'Navs',
  //           translate: 'MENU.UI.COMPONENTS.COMPONENT',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/navs'
  //         },
  //         {
  //           id: 'components-pagination',
  //           title: 'Pagination',
  //           translate: 'MENU.UI.COMPONENTS.PAGINATION',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/pagination'
  //         },
  //         {
  //           id: 'components-pill-badges',
  //           title: 'Pill Badges',
  //           translate: 'MENU.UI.COMPONENTS.PBADGES',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/pill-badges'
  //         },
  //         {
  //           id: 'components-pills',
  //           title: 'Pills',
  //           translate: 'MENU.UI.COMPONENTS.PILLS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/pills'
  //         },
  //         {
  //           id: 'components-popovers',
  //           title: 'Popovers',
  //           translate: 'MENU.UI.COMPONENTS.POPOVERS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/popovers'
  //         },
  //         {
  //           id: 'components-progress',
  //           title: 'Progress',
  //           translate: 'MENU.UI.COMPONENTS.PROGRESS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/progress'
  //         },
  //         {
  //           id: 'components-ratings',
  //           title: 'Ratings',
  //           translate: 'MENU.UI.COMPONENTS.RATINGS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/ratings'
  //         },
  //         {
  //           id: 'components-spinner',
  //           title: 'Spinner',
  //           translate: 'MENU.UI.COMPONENTS.SPINNER',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/spinner'
  //         },
  //         {
  //           id: 'components-tabs',
  //           title: 'Tabs',
  //           translate: 'MENU.UI.COMPONENTS.TABS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/tabs'
  //         },
  //         {
  //           id: 'components-timeline',
  //           title: 'Timeline',
  //           translate: 'MENU.UI.COMPONENTS.TIMELINE',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/timeline'
  //         },
  //         {
  //           id: 'components-toasts',
  //           title: 'Toasts',
  //           translate: 'MENU.UI.COMPONENTS.TOASTS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/toasts'
  //         },
  //         {
  //           id: 'components-tooltips',
  //           title: 'Tooltips',
  //           translate: 'MENU.UI.COMPONENTS.TOOLTIPS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'components/tooltips'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'extensions',
  //       title: 'Extension',
  //       translate: 'MENU.UI.EX.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'plus-circle',
  //       children: [
  //         {
  //           id: 'ex-sweet-alerts',
  //           title: 'Sweet Alerts',
  //           translate: 'MENU.UI.EX.SWEET_ALERTS',
  //           icon: 'circle',
  //           type: 'item',
  //           url: '/extensions/sweet-alerts'
  //         },
  //         {
  //           id: 'ex-blockui',
  //           title: 'BlockUI',
  //           translate: 'MENU.UI.EX.BLOCKUI',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/blockui'
  //         },
  //         {
  //           id: 'ex-toastr',
  //           title: 'Toastr',
  //           translate: 'MENU.UI.EX.TOASTER',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/toastr'
  //         },
  //         {
  //           id: 'ex-noui-slider',
  //           title: 'Slider',
  //           translate: 'MENU.UI.EX.SLIDER',
  //           icon: 'circle',
  //           type: 'item',
  //           url: '/extensions/noui-slider'
  //         },
  //         {
  //           id: 'ex-drag-drop',
  //           title: 'Drag & Drop',
  //           translate: 'MENU.UI.EX.DRAGDROP',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/drag-drop'
  //         },
  //         {
  //           id: 'ex-tour',
  //           title: 'Tour',
  //           translate: 'MENU.UI.EX.TOUR',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/tour'
  //         },
  //         {
  //           id: 'ex-clip-board',
  //           title: 'Clipboard',
  //           translate: 'MENU.UI.EX.CLIPBOARD',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/clipboard'
  //         },
  //         {
  //           id: 'ex-media-player',
  //           title: 'Media Player',
  //           translate: 'MENU.UI.EX.MEDIAPLAYER',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/media-player'
  //         },
  //         {
  //           id: 'ex-content-menu',
  //           title: 'Context Menu',
  //           translate: 'MENU.UI.EX.CONTEXTMENU',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/context-menu'
  //         },
  //         {
  //           id: 'ex-swiper',
  //           title: 'Swiper',
  //           translate: 'MENU.UI.EX.SWIPER',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/swiper'
  //         },
  //         {
  //           id: 'ex-tree-view',
  //           title: 'Tree View',
  //           translate: 'MENU.UI.EX.TREEVIEW',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'extensions/tree-view'
  //         },
  //         {
  //           id: 'i18n',
  //           title: 'I18n',
  //           translate: 'MENU.UI.EX.I18N',
  //           icon: 'circle',
  //           type: 'item',
  //           url: '/extensions/i18n'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'page-layouts',
  //       title: 'Page Layouts',
  //       translate: 'MENU.UI.LAYOUTS.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'layout',
  //       children: [
  //         {
  //           id: 'layout-collapsed-menu',
  //           title: 'Collapsed Menu',
  //           translate: 'MENU.UI.LAYOUTS.COLLAPSED_MENU',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'ui/page-layouts/collapsed-menu'
  //         },
  //         {
  //           id: 'layout-boxed',
  //           title: 'Boxed Layout',
  //           translate: 'MENU.UI.LAYOUTS.BOXED_LAYOUT',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'ui/page-layouts/boxed-layout'
  //         },
  //         {
  //           id: 'layout-without-menu',
  //           title: 'Without Menu',
  //           translate: 'MENU.UI.LAYOUTS.WITHOUT_MENU',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'ui/page-layouts/without-menu'
  //         },
  //         {
  //           id: 'layout-empty',
  //           title: 'Layout Empty',
  //           translate: 'MENU.UI.LAYOUTS.LAYOUT_EMPTY',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'ui/page-layouts/layout-empty'
  //         },
  //         {
  //           id: 'layout-blank',
  //           title: 'Layout Blank',
  //           translate: 'MENU.UI.LAYOUTS.LAYOUT_BLANK',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'ui/page-layouts/layout-blank'
  //         }
  //       ]
  //     }
  //   ]
  // },

  // Forms & Tables

  // {
  //   id: 'forms-table',
  //   type: 'section',
  //   title: 'Forms & Tables',
  //   translate: 'MENU.FT.SECTION',
  //   icon: 'file-text',
  //   children: [
  //     {
  //       id: 'form-elements',
  //       title: 'Form Elements',
  //       translate: 'MENU.FT.ELEMENT.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'copy',
  //       children: [
  //         {
  //           id: 'form-elements-input',
  //           title: 'Input',
  //           translate: 'MENU.FT.ELEMENT.INPUT',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/input'
  //         },
  //         {
  //           id: 'form-elements-inputgroups',
  //           title: 'Input Groups',
  //           translate: 'MENU.FT.ELEMENT.INPUTGROUPS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/input-groups'
  //         },
  //         {
  //           id: 'form-elements-inputmask',
  //           title: 'Input Mask',
  //           translate: 'MENU.FT.ELEMENT.INPUTMASK',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/input-mask'
  //         },
  //         {
  //           id: 'form-elements-textarea',
  //           title: 'Textarea',
  //           translate: 'MENU.FT.ELEMENT.TEXTAREA',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/textarea'
  //         },
  //         {
  //           id: 'form-elements-checkbox',
  //           title: 'Checkbox',
  //           translate: 'MENU.FT.ELEMENT.CHECKBOX',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/checkbox'
  //         },
  //         {
  //           id: 'form-elements-radio',
  //           title: 'Radio',
  //           translate: 'MENU.FT.ELEMENT.RADIO',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/radio'
  //         },
  //         {
  //           id: 'form-elements-switch',
  //           title: 'Switch',
  //           translate: 'MENU.FT.ELEMENT.SWITCH',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/switch'
  //         },
  //         {
  //           id: 'form-elements-select',
  //           title: 'Select',
  //           translate: 'MENU.FT.ELEMENT.SELECT',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/select'
  //         },
  //         {
  //           id: 'form-elements-numberInput',
  //           title: 'Number Input',
  //           translate: 'MENU.FT.ELEMENT.NUMBERINPUT',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/number-input'
  //         },
  //         {
  //           id: 'form-elements-file-uploader',
  //           title: 'File Uploader',
  //           translate: 'MENU.FT.ELEMENT.FILEUPLOADER',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'forms/form-elements/file-uploader'
  //         },
  //         {
  //           id: 'form-elements-quill-editor',
  //           title: 'Quill Editor',
  //           translate: 'MENU.FT.ELEMENT.QUILLEDITOR',
  //           icon: 'circle',
  //           type: 'item',
  //           url: 'forms/form-elements/quill-editor'
  //         },
  //         {
  //           id: 'form-elements-flatpicker',
  //           title: 'Flatpicker',
  //           translate: 'MENU.FT.ELEMENT.FLATPICKER',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/flatpickr'
  //         },
  //         {
  //           id: 'form-elements-date-time-icker',
  //           title: 'Date & Time Picker',
  //           translate: 'MENU.FT.ELEMENT.DATETIMEPICKER',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'forms/form-elements/date-time-picker'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'form-layouts',
  //       title: 'Form Layouts',
  //       translate: 'MENU.FT.LAYOUTS',
  //       type: 'item',
  //       icon: 'box',
  //       url: 'forms/form-layout'
  //     },
  //     {
  //       id: 'form-wizard',
  //       title: 'Form Wizard',
  //       translate: 'MENU.FT.WIZARD',
  //       type: 'item',
  //       icon: 'package',
  //       url: 'forms/form-wizard'
  //     },
  //     {
  //       id: 'form-validation',
  //       title: 'Form Validations',
  //       translate: 'MENU.FT.VALIDATION',
  //       type: 'item',
  //       icon: 'check-circle',
  //       url: 'forms/form-validation'
  //     },
  //     {
  //       id: 'form-repeater',
  //       title: 'Form Repeater',
  //       translate: 'MENU.FT.REPEATER',
  //       type: 'item',
  //       icon: 'rotate-cw',
  //       url: 'forms/form-repeater'
  //     },
  //     {
  //       id: 'tables-table',
  //       title: 'Table',
  //       translate: 'MENU.FT.TABLE',
  //       type: 'item',
  //       icon: 'server',
  //       url: 'tables/table'
  //     },
  //     {
  //       id: 'tables-datatable',
  //       title: 'DataTables',
  //       translate: 'MENU.FT.DATATABLES',
  //       type: 'item',
  //       icon: 'grid',
  //       url: 'tables/datatables'
  //     }
  //   ]
  // },

  // Charts & Maps

  // {
  //   id: 'charts-maps',
  //   type: 'section',
  //   title: 'Charts & Maps',
  //   translate: 'MENU.CM.SECTION',
  //   icon: 'bar-chart-2',
  //   children: [
  //     {
  //       id: 'charts',
  //       title: 'Charts',
  //       translate: 'MENU.CM.CHARTS.COLLAPSIBLE',
  //       type: 'collapsible',
  //       icon: 'pie-chart',
  //       badge: {
  //         title: '2',
  //         translate: 'MENU.CM.CHARTS.BADGE',
  //         classes: 'badge-light-danger badge-pill'
  //       },
  //       children: [
  //         {
  //           id: 'apex',
  //           title: 'Apex',
  //           translate: 'MENU.CM.CHARTS.APEX',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'charts-and-maps/apex'
  //         },
  //         {
  //           id: 'chartJs',
  //           title: 'ChartJS',
  //           translate: 'MENU.CM.CHARTS.CHARTJS',
  //           type: 'item',
  //           icon: 'circle',
  //           url: 'charts-and-maps/chartjs'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'google-maps',
  //       title: 'Google Maps',
  //       translate: 'MENU.CM.MAPS',
  //       icon: 'map',
  //       type: 'item',
  //       url: 'charts-and-maps/google-maps'
  //     }
  //   ]
  // },

  // Others

  // {
  //   id: 'others',
  //   type: 'section',
  //   title: 'Others',
  //   translate: 'MENU.OTHERS.SECTION',
  //   icon: 'box',
  //   children: [
  //     {
  //       id: 'menu-levels',
  //       title: 'Menu Levels',
  //       translate: 'MENU.OTHERS.LEVELS.COLLAPSIBLE',
  //       icon: 'menu',
  //       type: 'collapsible',
  //       children: [
  //         {
  //           id: 'second-level',
  //           title: 'Second Level',
  //           translate: 'MENU.OTHERS.LEVELS.SECOND',
  //           icon: 'circle',
  //           type: 'item',
  //           url: '#'
  //         },
  //         {
  //           id: 'second-level1',
  //           title: 'Second Level',
  //           translate: 'MENU.OTHERS.LEVELS.SECOND1.COLLAPSIBLE',
  //           icon: 'circle',
  //           type: 'collapsible',
  //           children: [
  //             {
  //               id: 'third-level',
  //               title: 'Third Level',
  //               translate: 'MENU.OTHERS.LEVELS.SECOND1.THIRD',
  //               type: 'item',
  //               url: '#'
  //             },
  //             {
  //               id: 'third-level1',
  //               title: 'Third Level',
  //               translate: 'MENU.OTHERS.LEVELS.SECOND1.THIRD1',
  //               type: 'item',
  //               url: '#'
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       id: 'disabled-menu',
  //       title: 'Disabled Menu',
  //       translate: 'MENU.OTHERS.DISABLED',
  //       icon: 'eye-off',
  //       type: 'item',
  //       url: '#',
  //       disabled: true
  //     },
  //     {
  //       id: 'documentation',
  //       title: 'Documentation',
  //       translate: 'MENU.OTHERS.DOCUMENTATION',
  //       icon: 'file-text',
  //       type: 'item',
  //       url: 'https://pixinvent.com/demo/vuexy-angular-admin-dashboard-template/documentation',
  //       externalUrl: true,
  //       openInNewTab: true
  //     },
  //     {
  //       id: 'raise-support',
  //       title: 'Raise Support',
  //       translate: 'MENU.OTHERS.SUPPORT',
  //       icon: 'life-buoy',
  //       type: 'item',
  //       url: 'https://pixinvent.ticksy.com/',
  //       externalUrl: true,
  //       openInNewTab: true
  //     }
  //   ]
  // }
];

import { Injectable } from '@angular/core';
import { GlobalService } from 'app/Services/global.service';
import { GetMyAttachmentsInputModel, GetOnThisDayInputModel, GetQuickPostListInputModel } from 'app/home/home-model.model';
@Injectable({
  providedIn: 'root'
})
export class QuickpostlistService {
   public gridRefresh:boolean=false;
  constructor(private globalService: GlobalService) { }
  GetUserQuickPosts(data){
    const path = 'api/UserQuickPost/GetUserQuickPosts';
    return this.globalService.PostDataById(data,path);
  }

  GetUserQuickPostList(_data:GetQuickPostListInputModel){
    const path = 'api/UserQuickPost/GetUserQuickPostList'
    return this.globalService.PostDataById(_data, path);
  }

  getGridRefresh():boolean{
  //console.log(this.gridRefresh)
    return this.gridRefresh;
  }

  setGridRefresh(refresh) {
    //console.log('refresh')
    this.gridRefresh = refresh;
    //console.log(this.gridRefresh)
  }

  GetOnThisDayQuickPosts(getOnThisDayInputModel:GetOnThisDayInputModel){
    const path = 'api/Home/GetOnThisDayQuickPosts';
    return this.globalService.PostDataById(getOnThisDayInputModel,path);
  }

  GetMyAttachments(getMyAttachmentsInputModel:GetMyAttachmentsInputModel){
    const path = 'api/MyBlogs/GetMyAttachments';
    return this.globalService.PostDataById(getMyAttachmentsInputModel,path);
  }

  GetAboutMeData(data){
    const path = 'api/MyBlogs/GetAboutMeData';
    return this.globalService.PostDataById(data,path);
  }

  CreateUpdateAboutMeData(data){
    const path = 'api/MyBlogs/CreateUpdateAboutMeData';
    return this.globalService.PostDataById(data,path);
  }

}

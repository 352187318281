<div class="header-div" *ngIf="currentUser==null">
    <div class="row">
        <span class="px-5">
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="58" width="auto" loading="lazy" />
        </span>
        <span class="row px-5 ml-auto" style="align-items: center; gap: 10px;">
            <button type="button" class="btn btn-primary px-3 new-button" (click)="signup(2)"><i data-feather="edit-2"
                    class="mr-0 mr-sm-1"></i><span class="d-none d-lg-inline-block px-1">
                    Sign Up</span></button>
            <button type="button" class="btn btn-danger px-3" (click)="signup(1)" >Log In <i data-feather="log-in"
                    class="mr-0 mr-sm-1"></i><span class="d-none d-lg-inline-block px-1"></span></button>
        </span>
    </div>
</div>
<div class="content-body">
    <div id="user-profile">
        <div class="row">
            <div  *ngIf="currentUser!=null" class="col-12">
                  <!-- <div (click)="goBack()" style="color:#fff; background-color: #52057f;" class="profile-header p-2 mb-2">
                        <i [data-feather]="'arrow-left-circle'" ></i>
                    </div> -->
                    <div  class="d-flex justify-content-between header align-items-center">
                        <button type="button" class="btn btn-primary .btn-style" (click)="goBack()" rippleEffect placement="top" ngbTooltip="Go Back">
                          <i data-feather="arrow-left-circle" class="mr-1 font-medium-4"></i>
                        </button>    
                      </div>
            </div>
            <div class="col-12">
                <div class="card profile-header mb-2">
                    <!-- profile cover photo -->
                    <div class="cover-image-container">
                        <img class="card-img-top cover-image" [src]="aboutMeData?.CoverImagePath"
                            alt="User Profile Image" />
                        <!-- <div class="icon-container cover-icon" style="color: #fff;">
                            <i [data-feather]="'camera'"></i>
                        </div> -->
                    </div>
                    <!--/ profile cover photo -->
        
                    <div class="position-relative">
                        <!-- profile picture -->
                        <div class="profile-img-container d-flex align-items-center">
                            <div class="profile-img">
                                <img src="{{aboutMeData?.ProfileImagePath}}" class="rounded img-fluid"
                                    alt="Card image" />
                                <!-- <div class="icon-container profile-icon" style="color: #fff;">
                                    <i [data-feather]="'camera'"></i>
                                </div> -->
                            </div>
                            <!-- profile title -->
                            <div class="profile-title ml-3">
                                <h2 class="text-white">{{ aboutMeData?.FullName }}</h2>
                            </div>
                        </div>
                    </div>
        
                    <div class="profile-header-nav">
                        <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                            <button class="btn btn-icon navbar-toggler" type="button"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu = !toggleMenu"
                                rippleEffect>
                                <i data-feather="align-justify" class="font-medium-5"></i>
                            </button>
                            <!-- collapse  -->
                            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="toggleMenu">
                                <div class="profile-tabs d-flex justify-content-between flex-wrap mt-1 mt-md-0">
                                    <ul class="nav nav-pills mb-0 flex-1">
                                        <ng-container *ngFor="let tab of tabs">
                                            <li class="nav-item flex-1">
                                                <a class="nav-link font-weight-bold" [class.active]="tab.id === active"
                                                    (click)="active = tab.id;onMainTabActive(tab.id)">
                                                    <span class="d-none d-md-block">{{ tab.title }}</span>
                                                    <i *ngIf="tab.icon" [data-feather]="tab.icon"
                                                        class="d-block d-md-none"></i>
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                            <!--/ collapse  -->
                        </nav>
                        <!--/ navbar -->
                    </div>
                </div>
            </div>
        </div>
        
        <div class="tab-content">
            <div id="diary" class="tab-pane" [class.active]="active === 'diary'">
              <div class="row align-items-end mx-0">
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex align-items-center">
                    <label class="mr-2">Date Range:</label>
                    <ng-select class="flex-grow-1" placeholder="Select Date Range" [items]="dayFilterOptions"
                      bindLabel="label" (ngModelChange)="onDateRangeChange($event)"
                      [(ngModel)]="selectedDateRange"></ng-select>
                  </div>
                </div>
                <div class="col-md-6 col-lg-3">
                  <div class="d-flex align-items-center">
                    <label class="mr-2">Month:</label>
                    <ng-select class="flex-grow-1" placeholder="Select Month" [items]="monthList"
                      (ngModelChange)="onMonthChange($event)" [(ngModel)]="selectedMonth"></ng-select>
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <!-- First datepicker with label -->
                  <div class="d-flex align-items-center">
                    <label for="datepicker1" class="mr-2" style="text-wrap: nowrap;">From Date:</label>
                    <input type="text" class="form-control" style="height: 38px;" id="datepicker1" bsDatepicker placeholder="Select date"
                      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" (ngModelChange)="validateDates('fromDate',$event)"
                      [(ngModel)]="fromDate">
                  </div>
                </div>
                <div class="col-md-6 col-lg-2">
                  <!-- Second datepicker with label -->
                  <div class="d-flex align-items-center">
                    <label for="datepicker2" class="mr-2" style="text-wrap: nowrap;">To Date:</label>
                    <input type="text" class="form-control" style="height: 38px;" id="datepicker2" bsDatepicker placeholder="Select date"
                      [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" (ngModelChange)="validateDates('toDate',$event)"
                      [(ngModel)]="toDate">
                  </div>
                </div>
                <div class="col-md-12 col-lg-2">
                  <!-- Search button -->
                  <button class="btn btn-primary btn-block" style="height: 38px;" (click)="onSearch()">Search</button>
                </div>
              </div>
                <div *ngIf="latestQuickPost.length>0; else skeletonLoader" class="row m-0 pt-2">
                <ng-container *ngFor="let item of latestQuickPost; let j = index" >
                    <div class="col-lg-6 col-md-6 col-sm-12 px-1 quick-post-item" >
                      <div class="card card-home itemrow" style="margin-bottom: 10px;">
                        <ngb-carousel [interval]="3000" *ngIf="latestQuickPost[j]?.QuickPostDocumentList.length > 0">
                          <ng-template ngbSlide
                            *ngFor="let image of imagetag(latestQuickPost[j]?.QuickPostDocumentList); let i = index">
                            <div class="carousel-img-wrapper">
                              <div
                                style="height: 308px; background-image: url('{{image}}'); background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 8px;filter: blur(8px);">
                     
                              </div>
                              <img style="width: auto;position: absolute; padding: 3% 0 3% 0; top: 50%;left: 50%;height: 100%;transform: translate(-50%, -50%) scale(1);"
                          src="{{image}}">
                            </div>
                          </ng-template>
                        </ngb-carousel>
                        <div *ngIf="latestQuickPost[j]?.QuickPostDocumentList.length == 0"
                          style="height: 308px; background-image: url('assets/images/pages/Asset 84.png'); background-repeat: no-repeat; background-size: cover; background-position: center; border-radius: 8px 8px 0 0;">
                        </div>
                        <div class="card-body" style="min-height:210px;">
                          <h4 class="card-title" style="color:rgba(50, 48, 145, 1); margin-bottom: 8px;">
                            {{latestQuickPost[j]?.Title|truncate:50}}</h4>
                          <div>
                            <b style="color: #52057f;">{{latestQuickPost[j]?.DiaryDate | date : 'MMMM dd , yyyy'}}</b>
                            {{btnname}}
                            Time From: <strong>{{latestQuickPost[j]?.DiaryTimeFrom | date:'h:mm a'}}</strong>
                            {{btnname}} Time To: <b>{{latestQuickPost[j]?.DiaryTimeTo | date:'h:mm a'}}</b>
                            <br> <i *ngIf="latestQuickPost[j]?.Location" data-feather="map-pin"></i>
                            {{latestQuickPost[j]?.Location}}
                          </div>
                          <div *ngFor="let id of parseTags(latestQuickPost[j]?.TagCategory) let i = index;let isLast = last"
                            class="badge badge-light-primary profile-badge card-home"
                            style="font-size:small; font-family: lato; margin: 5px 5px;">
                            <span *ngIf="i <= 4" [ngbTooltip]="id.name">{{ id.name | truncate: 10 }}</span>
                            <span *ngIf="i > 4 && isLast">+{{ i % 4 }} more</span>
                          </div>
                          <div class="card-text wrap m-0 no-spacing" appRemoveMargins style="margin:0" 
                            [innerHTML]="latestQuickPost[j]?.PostContent | truncate: 320">
                          </div>
                        </div>
                        <div class=" card-footers" style="text-align: right;">
                          <a class="text-muted"
                            (click)="EditQuickPost(latestQuickPost[j]?.UserQuickPostId)">Read More</a>
                        </div>
                      </div>
                    </div>
                 
                </ng-container>
                </div>
                <ng-template #skeletonLoader>
                    <div>
                        <img style="padding-left: 40%"
                        src="assets/images/pages/noimageuploaded.png">
                    </div>
                  </ng-template>
            </div>

            <!-------------------------------------------------------------Diary Entries End------------------------------------------------------------------------------>

            <div id="aboutme" class="tab-pane" [class.active]="active === 'aboutme'">
                <div class="row m-0 card">
                   <div class="p-3">
                    <p class="heading-font">About Me </p>
                    <p>{{ aboutMeData?.AboutMeDescription}}</p>
                    <hr class="solid">
                    <span class="row m-0">
                        <span class="col-6 p-0">
                            <p class="heading-font m-0">Joined</p>
                            <p><i [data-feather]="'calendar'"></i> {{ aboutMeData?.JoinedDate |date:dateformat}}</p>
                        </span>
                        <span class="col-6 p-0">
                            <p class="heading-font m-0">Email Address</p>
                            <p><i [data-feather]="'mail'"></i> {{aboutMeData?.EmailAddress}}</p>
                        </span>
                    </span>
                    <span class="row m-0">
                        <span *ngIf="aboutMeData?.PersonalWebUrl" class="col-6 p-0">
                            <p class="heading-font m-0">My Personal URL</p>
                            <p><i [data-feather]="'globe'"></i> {{aboutMeData?.PersonalWebUrl}}</p>
                        </span>
                        <span *ngIf="aboutMeData?.CurrentUserAddress?.FullAddress" class="col-6 p-0">
                            <p class="heading-font m-0">Lives</p>
                            <p><i [data-feather]="'map-pin'"></i> {{aboutMeData?.CurrentUserAddress?.FullAddress}}</p>
                        </span>
                    </span>
                   </div>
                </div>
            </div>



            <!-------------------------------------------------------------about-me------------------------------------------------------------------------------>





            <div id="photos" class="tab-pane" [class.active]="active === 'photos'">
                <ul ngbNav #navJustified="ngbNav" class="nav-tabs nav-justified">
                  <li ngbNavItem (click)="onTabActive('Images')">
                    <a ngbNavLink>Images</a>
                    <ng-template ngbNavContent>
                      <div *ngIf="attachmentlistimage.length==0"><img style="padding-left: 40%"
                          src="assets/images/pages/noimageuploaded.png"></div>
                      <div *ngIf="attachmentlistimage.length>0" class=" scroll-area">
                        <div id="animated-thumbnails-gallery">
                          <lightgallery #AddressGallery [settings]="settings" [onInit]="onInitGallery">
                            <a *ngFor="let item of attachmentlistimage"
                              [attr.data-video]="item.type === 'Videos' ? item.data : null"
                              [attr.href]="item.type === 'Images' ? item.src : null"
                              [data-poster]="item.type === 'Videos' ? item.thumb :null" className="gallery-item"
                              data-sub-html="<p>{{item.id}}</p>">
                              <img *ngIf="item.type=='Images'" class="img-responsive justify-gallery" src="{{item.src}}"
                                alt="{{item.id}}" />
                            </a>
                          </lightgallery>
                        </div>
                      </div>
                      <!-- Load More Button for Images Tab -->
                      <!-- <button class="btn btn-primary floatingloadmore-button" [routerLink]="['/my-image-gallery/list']" *ngIf="showLoadMoreButtonImages">Load More Images</button> -->
                      <ngb-pagination cdkDragHandle class="floatingloadmore-button" *ngIf="attachmentlistimage.length>0" [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Images')"  [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true"
                        [boundaryLinks]="true">
                        <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                            [class]="'font-weight-bolder'"></span>Prev
                        </ng-template>
                        <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                            [class]="'font-weight-bolder'"></span>
                        </ng-template>
                      </ngb-pagination>
        
                    </ng-template>
                  </li>
                  <li ngbNavItem (click)="onTabActive('Videos')">
                    <a ngbNavLink>Videos</a>
                    <ng-template ngbNavContent>
                      <div *ngIf="attachmentlistimage.length==0"><img style="padding-left: 40%"
                          src="assets/images/pages/noimageuploaded.png">
                      </div>
                      <div *ngIf="attachmentlistimage.length>0" class=" scroll-area">
                        <lightgallery [settings]="settings" [onInit]="onInitGallery">
                          <a *ngFor="let item of attachmentlistimage" [attr.data-video]="item.type === 'Videos' ? item.data : null"
                            [attr.data-video]="item.type === 'Videos' ? item.data : null"
                            [attr.href]="item.type === 'Images' ? item.src : null"
                            [data-poster]="item.type === 'Videos' ? item.thumb :null" className="gallery-item"
                            data-sub-html="<p>{{item.id}}</p>">
                            <img *ngIf="item.type === 'Videos'" [src]="item.thumb" class="img-responsive justify-gallery"
                              alt="{{item.id}}" />
                          </a>
                        </lightgallery>
                      </div>
                      <!-- Load More Button for Videos Tab -->
                      <ngb-pagination  cdkDragHandle class="floatingloadmore-button" *ngIf="attachmentlistimage.length>0" [collectionSize]='pagesNumber' [pageSize]="20" (pageChange)="loadPage($event,'Videos')"  [(page)]="pageAdvancedEllipses" [maxSize]="5" [rotate]="true"
                        [boundaryLinks]="true">
                        <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                            [class]="'font-weight-bolder'"></span>Prev
                        </ng-template>
                        <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                            [class]="'font-weight-bolder'"></span>
                        </ng-template>
                      </ngb-pagination>
                    </ng-template>
                  </li>
        
                </ul>
                <div [ngbNavOutlet]="navJustified" class="mt-2"></div>
              </div>


            <!-------------------------------------------------------------Photos End------------------------------------------------------------------------------>


        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CoreConfigService } from '@core/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { AuthenticationService } from 'app/auth/service';
import { ProfileService } from 'app/main/pages/profile/profile.service';
import lgZoom from 'lightgallery/plugins/zoom';
import thumbnail from 'lightgallery/plugins/thumbnail';
import pager from 'lightgallery/plugins/pager';
import autoPlay from 'lightgallery/plugins/autoplay';
import lgvideo from 'lightgallery/plugins/video';
import lgVideo from 'lightgallery/plugins/video';
import vimeoVideo from 'lightgallery/plugins/video';
import videoThumbnail from 'lightgallery/plugins/video';
import fullScreen from 'lightgallery/plugins/fullscreen';
import rotate from 'lightgallery/plugins/rotate';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { LightGallery } from 'lightgallery/lightgallery';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { GlobalService } from 'app/Services/global.service';
import { UsernameService } from '../username.service';
import { FileType, QuickPostType, Status } from 'app/enums';
import { GetPublicAttachmentsInputModel, GetPublicProfileDataInputModel, GetPublicQuickPostLoadDataInputModel, GetPublicQuickPostsInputModel } from '../username.model';
import { E } from 'assets/extensions/extensions';
import { GetMyAttachmentsInputModel } from 'app/home/home-model.model';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit {
  @ViewChild('AddressGallery') lightGallery!: LightGallery;
  public coreConfig: any;
  private _unsubscribeAll: Subject<any>;
  public data: any;
  public toggleMenu = true;
  public currentUser: User;
  active: string = 'diary';
  tabs = [
    { id: 'diary', title: 'Diary Entries', icon: 'book' },
    { id: 'aboutme', title: 'About Me', icon: 'users' },
    { id: 'photos', title: 'My Public Photos', icon: 'image' },  
  ];
  public error = '';
  aboutMeData:any;
  filteredImage: any;
  names1: any;
  userName;
  latestQuickPost
  settings: any = {
    counter: false,
    appendSubHtmlTo: '.lg-toolbar',
    commentBox: true,
    lgVideo: true,
    download: false,
    thumbnail: true,
    slideVideo: true,
    pager:false,
    autoplayFirstVideo: false,
    autoplayVideoOnSlide:false,
    plugins: [
      lgZoom,
      // pager,
      autoPlay,
      fullScreen,
      rotate,
      thumbnail,
      videoThumbnail,
      vimeoVideo,
      lgVideo,
      lgvideo,
      //share
    ] // comment, share (options are commented for future use)
  };
  private needRefresh = false;
  galleryRefresh:LightGallery;
  cache: { [key: string]: string } = {};
  public attachmentlist: any = [];
  public attachmentlistimage: any = [];
  loading:boolean=false;
  getPublicQuickPostsInputModel:GetPublicQuickPostsInputModel;
   dayFilterOptions
  monthList;
  selectedDateRange
  selectedMonth: string;
  fromDate: Date;
  toDate: Date;
  getPublicProfileDataInputModel:GetPublicProfileDataInputModel;
  getPublicAttachmentsInputModel: GetPublicAttachmentsInputModel;
  public pageAdvancedEllipses = 1;
  public pagesNumber;
  dateformat: string;
  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(private _title: Title,
    private _coreConfigService: CoreConfigService,
    private _authenticationService: AuthenticationService,
    private loadingScreenService: LoadingScreenService,
    private _router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private encrDecr:EncrDecrService,
    private globalService:GlobalService,
    private usernameService:UsernameService,
    private _toastrService: CustomToastrService,
  ) { 
    this._title.setTitle("Noosom");
    this._unsubscribeAll = new Subject();
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    this.dayFilterOptions = [
      { value: 0, label: 'Today' },
      { value: 1, label: 'Tomorrow' },
      { value: 2, label: 'Yesterday' },
      { value: 3, label: 'This Week' },
      { value: 4, label: 'Next Week' },
      { value: 5, label: 'Previous Week' },
      { value: 6, label: 'This Month' },
      { value: 7, label: 'Next Month' },
      { value: 8, label: 'Previous Month' },
      { value: 9, label: 'This Year' },
      { value: 10, label: 'Next Year' },
      { value: 11, label: 'Previous Year' }
    ];
    this.dateformat=this.globalService.getDateFormat()
  }

  
  ngOnInit(): void {
    //console.log('abc')
    this.route.paramMap.subscribe(params => {
      this.userName=params.get('username')
      // if(params.get('username').length>8){
      //   this._router.navigate(['/pages/miscellaneous/UserNotFound']);
      // }
    });
    this.getPublicProfileDataInputModel={
      UserName:this.userName
    }
    this.GetPublicProfileData(this.getPublicProfileDataInputModel)
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      //console.log(this.coreConfig)
    });
    if(this.currentUser == null){
      this._coreConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          footer: {
            hidden: true
          },
          menu: {
            hidden: true
          },
          customizer: false,
          enableLocalStorage: false
        }
      };}
const currentDate = new Date();
this.monthList = this.generateMonthList();
    this.initialValues();

  }
  initialValues(){
    var CurrentDate = new Date();
    this.selectedDateRange = 'This Month';
    const currentDate = new Date();
    const currentMonthYear = currentDate.toLocaleString('en', { month: 'long' }) + ' ' + currentDate.getFullYear();
    this.selectedMonth = currentMonthYear;
    this.fromDate = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth(), 1);
    this.toDate = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth() + 1, 0);
    this.latestQuickPost=[]
    this.getPublicQuickPostsInputModel={
    UserName:this.userName,
    StartDate: E.GetDateStringOnly(this.fromDate),
    EndDate: E.GetDateStringOnly(this.toDate),
    GetMetaDataYN:false,
    NumberOfAttachments:4,
    }
  
   this.GetPublicQuickPosts(this.getPublicQuickPostsInputModel)
  }
  generateMonthList(): string[] {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const months = [];

    for (let month = 0; month < 12; month++) {
      const monthDate = new Date(currentYear, month, 1);
      const monthLabel = monthDate.toLocaleString('en', { month: 'long' }) + ' ' + currentYear;
      months.push(monthLabel);
    }

    return months;
  }
   
  onSearch(): void {
  

    this.latestQuickPost=[]
   // this.initialValues();
    this.getPublicQuickPostsInputModel={
      UserName:this.userName,
    StartDate: E.GetDateStringOnly(this.fromDate),
    EndDate: E.GetDateStringOnly(this.toDate),
    GetMetaDataYN:false,
    NumberOfAttachments:4
    }
    this.GetPublicQuickPosts(this.getPublicQuickPostsInputModel)
   // console.log(this.fromDate,this.toDate)
  }



  onDateRangeChange(value: any) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    this.selectedDateRange = value.label
    switch (value.value) {
      case 0: // Today
        const today = new Date();
        this.selectedMonth = this.getMonthLabel(today.getMonth(), today.getFullYear());
        this.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        this.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        break;
      case 1: // Tomorrow
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        this.selectedMonth = this.getMonthLabel(tomorrow.getMonth(), tomorrow.getFullYear());
        this.fromDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
        this.toDate = new Date(tomorrow.getFullYear(), tomorrow.getMonth(), tomorrow.getDate());
        break;
      case 2: // Yesterday
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.selectedMonth = this.getMonthLabel(yesterday.getMonth(), yesterday.getFullYear());
        this.fromDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
        this.toDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
        break;
      case 3: // This Week
        const today1 = new Date();
        const dayOfWeek = today1.getDay();
        const firstDayOfWeek = new Date(today1);
        const lastDayOfWeek = new Date(today1);

        // Set the first day of the week to Monday
        firstDayOfWeek.setDate(today1.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

        // Set the last day of the week to Sunday
        lastDayOfWeek.setDate(today1.getDate() - dayOfWeek + (dayOfWeek === 0 ? 0 : 7));

        this.selectedMonth = this.getMonthLabel(today1.getMonth(), today1.getFullYear());
        this.fromDate = firstDayOfWeek;
        this.toDate = lastDayOfWeek;
        break;

      case 4: // Next Week
        //const today = new Date();
        const nextWeekStart = new Date(currentDate);
        nextWeekStart.setDate(currentDate.getDate() + (1 - currentDate.getDay()) + 7); // Add 7 days for next week
        const nextWeekEnd = new Date(nextWeekStart);
        nextWeekEnd.setDate(nextWeekStart.getDate() + 6);

        this.selectedMonth = this.getMonthLabel(nextWeekStart.getMonth(), nextWeekStart.getFullYear());
        this.fromDate = nextWeekStart;
        this.toDate = nextWeekEnd;
        break;


      case 5: // Previous Week
        const previousWeekStart = new Date();
        previousWeekStart.setDate(previousWeekStart.getDate() - (previousWeekStart.getDay() === 0 ? 6 : previousWeekStart.getDay() - 1) - 7);
        const previousWeekEnd = new Date(previousWeekStart);
        previousWeekEnd.setDate(previousWeekEnd.getDate() + 6);

        this.selectedMonth = this.getMonthLabel(previousWeekStart.getMonth(), previousWeekStart.getFullYear());
        this.fromDate = previousWeekStart;
        this.toDate = previousWeekEnd;
        break;

      case 6: // This Month
        const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
        this.selectedMonth = this.getMonthLabel(currentMonth, currentYear);
        this.fromDate = firstDayOfMonth;
        this.toDate = lastDayOfMonth;
        break;
      case 7: // Next Month
        const nextMonth = new Date(currentYear, currentMonth + 1, 1);
        const firstDayOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1);
        const lastDayOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0);
        this.selectedMonth = this.getMonthLabel(nextMonth.getMonth(), nextMonth.getFullYear());
        this.fromDate = firstDayOfNextMonth;
        this.toDate = lastDayOfNextMonth;
        break;
      case 8: // Previous Month
        const previousMonth = new Date(currentYear, currentMonth - 1, 1);
        const firstDayOfPreviousMonth = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 1);
        const lastDayOfPreviousMonth = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 0);
        this.selectedMonth = this.getMonthLabel(previousMonth.getMonth(), previousMonth.getFullYear());
        this.fromDate = firstDayOfPreviousMonth;
        this.toDate = lastDayOfPreviousMonth;
        break;
      case 9: // This Year
        const firstDayOfYear = new Date(currentYear, 0, 1);
        const lastDayOfYear = new Date(currentYear, 11, 31);
        this.selectedMonth = this.getMonthLabel(currentMonth, currentYear);
        this.fromDate = firstDayOfYear;
        this.toDate = lastDayOfYear;
        break;
      case 10: // Next Year
        const nextYear = new Date(currentYear + 1, 0, 1);
        const firstDayOfNextYear = new Date(nextYear.getFullYear(), 0, 1);
        const lastDayOfNextYear = new Date(nextYear.getFullYear(), 11, 31);
        this.selectedMonth = this.getMonthLabel(nextYear.getMonth(), nextYear.getFullYear());
        this.fromDate = firstDayOfNextYear;
        this.toDate = lastDayOfNextYear;
        break;
      case 11: // Previous Year
        const previousYear = new Date(currentYear - 1, 0, 1);
        const firstDayOfPreviousYear = new Date(previousYear.getFullYear(), 0, 1);
        const lastDayOfPreviousYear = new Date(previousYear.getFullYear(), 11, 31);
        this.selectedMonth = this.getMonthLabel(previousYear.getMonth(), previousYear.getFullYear());
        this.fromDate = firstDayOfPreviousYear;
        this.toDate = lastDayOfPreviousYear;
        break;
     
    }
  }

  onMonthChange(selectedMonth: string): void {
    // Parse the selected month to extract month and year
    const [month, year] = selectedMonth.split(' ');

    // Calculate the first and last day of the selected month
    const firstDayOfMonth = new Date(`${month} 1, ${year}`);
    const lastDayOfMonth = new Date(new Date(firstDayOfMonth).setMonth(firstDayOfMonth.getMonth() + 1) - 1);

    // Update fromDate and toDate
    this.fromDate = firstDayOfMonth;
    this.toDate = lastDayOfMonth;
  }

  validateDates(name, event) {
    // Parse the selected dates
    if (name == 'fromDate') {
      this.fromDate = new Date(event)
    }
    if (name == 'toDate') {
      this.toDate = new Date(event)
    }
    const fromDate = new Date(this.fromDate);
    const toDate = new Date(this.toDate);

    // Check if fromDate is greater than toDate
    if (fromDate > toDate) {
      // Swap the dates
      const temp = this.fromDate;
      this.fromDate = this.toDate;
      this.toDate = temp;
    }
  }


  getMonthLabel(month: number, year: number): string {
    const monthDate = new Date(year, month, 1);
    return monthDate.toLocaleString('en', { month: 'long' }) + ' ' + year;
  }

  formatDate(date: Date): string {
    return (date.getDate() < 10 ? '0' : '') + date.getDate() + '/' + ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + date.getFullYear();
  }

  GetPublicQuickPosts(data){
    this.loadingScreenService.startLoading();
    this.usernameService.GetPublicQuickPosts(data)
      .subscribe(
        (result) => {
        let data =  JSON.parse(result._body)
          if (data.status == Status.Success) {
            let _data = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)))
            this.loading = false;
             this.latestQuickPost = _data?.PublicQuickPosts;
          }
          this.loadingScreenService.stopLoading();
        },
        (error) => {
          // this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
  }
  GetPublicProfileData(data){
    //console.log(data)
    this.loadingScreenService.startLoading();
    this.usernameService.GetPublicProfileData(data)
      .subscribe(
        (result) => {
        let data =  JSON.parse(result._body)
          if (data.status == Status.Success) {
            let _data = JSON.parse((this.encrDecr.get(Constant.EncrDecrKey, data.data)))
            this.loading = false;
            this.aboutMeData=_data?.AboutMeData;
          }
          else if(data.status == Status.UserNotFound){
            this._router.navigate(['/pages/miscellaneous/UserNotFound']);
          }
          this.loadingScreenService.stopLoading();
        },
        (error) => {
          // this.error = error;
          this.loading = false;
          this.loadingScreenService.stopLoading();
        }
      );
}



  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      if (this.lightGallery)
        this.galleryRefresh.refresh();
      this.needRefresh = false;
    }
  }

  get imageArray(): { id: number; name: string; tag: boolean }[][] {
    return this.filteredImage.map(post => this.imagetag(post));
  }
  
  imagetag(imagefun) {
    let jsonArrayImage = imagefun
    const filteredImage = jsonArrayImage.filter(item => item.FileType === "Images").map(item => item.DocumentPath)
  
    return filteredImage
  }
  
  get tagsArray(): { id: number; name: string; tag: boolean }[][] {
    return this.names1.map(post => this.parseTags(post.TagCategory));
  }
  
  parseTags(tagCategory: string): { id: number; name: string; tag: boolean }[] {
    const tags = JSON.parse(tagCategory);
    return tags.filter(tag => tag.tag);
  }

  onInitGallery = (detail) => {
    this.lightGallery = detail.instance;
    this.galleryRefresh = detail.instance;
    this.galleryRefresh.settings.licenseKey = this.aboutMeData?.LightGalleryLicenseKey
    const customHelpButton = `<button id="lg-custom-button-help" style="padding-top:10px" class="svg-icon lg-icon lg-group">
    <img class="svg-icon" style="height:28px;" src="assets/images/icons/HELP.png"></button>`;
    const $toolbar = this.lightGallery.$toolbar;
  
    $toolbar.append(customHelpButton);


   
    document.getElementById("lg-custom-button-help").addEventListener("click", () => {
      this.supportFunction();
    });
    
  };
  supportFunction() {

    Swal.fire({
      title: "Help!",
      text: "Information about the icons in the Light Gallery.",
      imageUrl: 'assets/images/pages/raw-icon-labels.png',
      showCancelButton: false,
      confirmButtonColor: '#7367F0',
      //cancelButtonColor: '#E42728',
      confirmButtonText: "Okay",
      cancelButtonText: "cancel",
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }

    })

  }

  onMainTabActive(tabid){
    if((tabid=='diary')){
      this.GetPublicQuickPosts(this.getPublicQuickPostsInputModel)
    }
    else if(tabid=='aboutme'){
      this.GetPublicProfileData(this.getPublicProfileDataInputModel)
      }
    else if(tabid=='photos'){
      this.getAttachmentList('Images')
    }
  }

  onTabActive(data){
    if(data=='Images'){
      this.getAttachmentList('Images')
    }
    else if(data=='Videos'){
      this.getAttachmentList('Videos')
    }
    }
  
    loadPage(event,filetype){
      this.pageAdvancedEllipses
     this.getAttachmentList(filetype,event)
    }
  
  getAttachmentList(FileType?,page?) {
    this.attachmentlistimage=[]
    this.getPublicAttachmentsInputModel = {
      UserName: this.userName,
      Page:page,
      Pagesize:20,
      FileType:FileType,
      GetMetaDataYN:false
    }
    this.loadingScreenService.startLoading();
    this.usernameService.GetPublicAttachments(this.getPublicAttachmentsInputModel).subscribe(async result => {
      if (result) {
        let data =  JSON.parse(result._body)
        if (data.status == Status.Success) {
          let _attachmentlist = JSON.parse(this.encrDecr.get(Constant.EncrDecrKey, data.data));
          if (_attachmentlist) {
            const imageDocs = _attachmentlist.filter(doc => doc.FileType.toLocaleLowerCase() === "images")
            const videoDocs = _attachmentlist.filter(doc => doc.FileType.toLocaleLowerCase() === "videos")
            //console.log(_attachmentlist[0]?.TotalNumberOfRecords)
            if(_attachmentlist[0]?.TotalNumberOfRecords>0)
             this.pagesNumber=_attachmentlist[0]?.TotalNumberOfRecords;
            const imagePromises = imageDocs.map(doc =>
                this.AddItemToGallery1(doc.AttachmentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.FileType)
            );
        
            const videoPromises = videoDocs.map(doc =>
                this.AddItemToGallery(doc.AttachmentId, doc.DocumentPath, doc.DocumentOldName, doc.Size, doc.FileType)
            );
        
            await Promise.all(imagePromises);
            await Promise.all(videoPromises);
            this.needRefresh = true;
        } 
        this.loadingScreenService.stopLoading();
        }
        else {
          this.loadingScreenService.stopLoading();
          this._toastrService.Error(data.Message, Constant.ErrorToastrTitle);
        }
      }
    });
  }
  
  async AddItemToGallery1(_id: number, _url: string, documentname: string, documentsize: string, filetype: string): Promise<any> {
    this.attachmentlistimage = [
      ...this.attachmentlistimage,
      {
        id: _id,
        size: '280-100',
        src: _url,
        data: documentname,
        thumb: _url,
        subHtml: documentname, documentsize,
        type: filetype,
      }
    ];
    this.needRefresh=true;
  }
  
  
  async AddItemToGallery(_id: number, _url: string, documentname: string, documentsize: string, filetype: string): Promise<any> {
    const loadingItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: 'https://noosom1sg.b-cdn.net/NoosomAssets/Images/noosomads/Logo-3-1.gif', // Placeholder or loading indicator for the thumbnail
      subHtml: documentname, documentsize,
      type: filetype,
    };
      
    this.attachmentlistimage.push(loadingItem);
    let thumb = this.cache[_url]; // Check if thumbnail is already in cache
  
    if (!thumb) {
      thumb = await this.generateThumbnailFromVideoURL(_url, filetype);
      this.cache[_url] = thumb; // Save the thumbnail to cache
    }
  
    const galleryItem = {
      id: _id,
      size: '280-100',
      src: _url,
      data: await this.generateVideoData(_url),
      thumb: thumb,
      subHtml: documentname, documentsize,
      type: filetype,
    };
    const index = this.attachmentlistimage.findIndex(item => item.id === _id);
    this.attachmentlistimage[index] = galleryItem;
   //console.log(this.attachmentlist)
  }
  
    async generateThumbnailFromVideoURL(videoURL: string, type: string): Promise<string> {
      if (type === "Videos") {
        return new Promise<string>((resolve, reject) => {
          const video = document.createElement('video');
          video.crossOrigin = 'anonymous'; // Enable cross-origin for video
          video.src = videoURL;
    
          video.addEventListener('loadedmetadata', () => {
            video.currentTime = 10.5; // Adjust as needed
    
            video.addEventListener('seeked', () => {
              const canvas = document.createElement('canvas');
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
    
              const ctx = canvas.getContext('2d');
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
              // Draw a play icon in the center of the canvas
              const playIconSize = 250; // Adjust the size as needed
              ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
              ctx.beginPath();
              ctx.moveTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 - playIconSize / 2);
              ctx.lineTo(canvas.width / 2 + playIconSize / 2, canvas.height / 2);
              ctx.lineTo(canvas.width / 2 - playIconSize / 2, canvas.height / 2 + playIconSize / 2);
              ctx.closePath();
              ctx.fill();
    
              // Resolve with the data URL of the canvas
              resolve(canvas.toDataURL('image/png'));
    
              // Clean up
              canvas.remove();
              video.remove();
            });
    
            video.addEventListener('error', (error) => {
              resolve("assets/images/pages/Cannot_play.png")
              //  reject(`Error loading video: ${error.message}`);
            });
          });
    
          video.addEventListener('error', (error) => {
            resolve("assets/images/pages/Cannot_play.png")
            //  reject(`Error loading video metadata: ${error.message}`);
          });
    
          // Load the video
          video.load();
        });
      }
    }
  
    async generateVideoData(src): Promise<string> {
      const videoData = {
        source: [{ src: src, type: 'video/mp4' }],
        attributes: { preload: true, controls: true }
      };
      return JSON.stringify(videoData);
    }
    
    EditQuickPost(data){
      let queryParameter={
        UserName:this.userName,
        GetMetaDataYN:false,
        UserQuickPostId:data,
        Profile:this.aboutMeData?.ProfileImagePath,
        Name:this.aboutMeData?.FullName
      }
      //this.queryParameter= this.encrDecr.set(Constant.EncrDecrKey, JSON.stringify(queryParameter))
      this._router.navigate(['/diary'],{queryParams:{ data:this.encrDecr.set(Constant.EncrDecrKey, JSON.stringify(queryParameter))}})
    }

    signup(data){
      if(data==1){
        this._router.navigate(['/pages/authentication/login-v2']);
      }
      else if(data ==2){
        this._router.navigate(['/pages/authentication/signup']);
      }
    }

    goBack(): void {
      this.location.back();
    }
    
    ngOnDestroy(): void {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }

}

import { AfterViewInit, Attribute, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextBoxComponent,
      multi: true
    }
  ]
})
export class TextBoxComponent implements OnInit, ControlValueAccessor {

  @ViewChild('textBox', { read: ElementRef }) textBox: ElementRef;
  public passwordTextType: boolean;

  @Input() for: string = "";
  @Input() label: string = "Default label";
  @Input() type: string = "text";
  @Input() formControlName: string = "";
  @Input() placeholder: string = "";
  @Input() showEyeIcon: boolean = false;
  @Input() classes: string = "";
  @Input() textBoxLinkLabel: string = "";
  @Input() textBoxLinkURL: string = "";
  @Input() required: boolean = false;
  @Input() showRequiredSign: boolean = false;
  @Input() applyMargin: boolean = false;
  @Input() tooltipInfo: string = "";
  @Input() position:string = "";
  @Input() showInfoIcon:boolean = false;
  @Input() tabindex: string = "";
  @Output() OnFocusOut: EventEmitter<any> = new EventEmitter();
  value!: string;
  public readonly: string = "";
  public autocomplete: string = "off";
  public autofocus: string = "";
  public aria_describedby: string = "";
  public for_lbl: string = "";
  OnChange: (val: string) => void;

  constructor(
    // @Attribute('tabindex') tabindex: string = "0",
    @Attribute('readonly') readonly: string = "",
    @Attribute('autocomplete') autocomplete: string = "",
    // @Attribute('autofocus') autofocus:string="",
    @Attribute('aria-describedby') aria_describedby: string = "",
    @Attribute('for') for_lbl: string = ""
  ) {
    // this.tabindex = tabindex;
    this.readonly = readonly;
    this.autocomplete = autocomplete;
    // this.autofocus=autofocus;
    this.aria_describedby = aria_describedby,
      this.for_lbl = for_lbl
  }
  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.OnChange = fn;
  }
  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {

  }

  ngOnInit(): void {
  }
  togglePasswordTextType() {
    if (this.type == "password") {
      this.passwordTextType = !this.passwordTextType;
    }
  }
  emitFocusOutEvent(event) {
    this.OnFocusOut.emit(event);
  }
}

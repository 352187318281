<!-- <div class="content-wrapper container-xxl p-0"> -->
    <div cdkDrag cdkDragRootElement=".cdk-overlay-pane" class="content-body">
        <div class="card-container">
            <div class="card" style="min-height: 55vh; border-radius: 8px 8px 0px 0px; margin: 0px; border:0px">
                <div cdkDragHandle  class="header">
                    <p class="title m-0">Select Files</p>
                    <div class="button-group">
                        <button *ngIf="attachmentlist" class="btn btn-warning" (click)="toggleSelectAll()" ngbTooltip="Toggle Selection">Select All</button>
                       
                     
                    <button class="close-button close" type="button"
        (click)="onCancelClick()" placement="right"
        ngbTooltip="Close">&times;</button> </div>
                  </div>
                  <div class="folder-container">
                    <div *ngIf="attachmentlist.length==0" class="center-container">
                        <img src="assets/images/pages/noimageuploaded.png" alt="No Image Uploaded" class="center-image">
                      </div>                          
                      <div class="folder" *ngFor="let item of attachmentlist; let i = index">
                        <div class="folder-content">
                            <input type="checkbox"[checked]="isFolderSelected(item?.DocumentPath)" (change)="onCheckboxChange($event.target.checked, item?.DocumentPath)"  class="folder-checkbox" />
                          <img src={{item?.DocumentPath}} ngbTooltip="Select" *ngIf="item.FileType=='Images'" placement="top" alt="Folder"  class="folder-icon">
                          <video src={{item?.DocumentPath}} ngbTooltip="Select" *ngIf="item.FileType=='Videos'" placement="top" alt="Folder"  class="folder-icon"></video>
                          <span class="folder-name"  placement="top" >{{ item.DocumentOldName|truncate:10 }}</span>
                        </div>
                      </div>
                      
                  </div>
            </div>
            <div class="card" style="margin:0; border:0px; border-radius: 0px 0px 8px 8px;">
            <div cdkDragHandle  class="header" style="border-radius: 0px 0px 8px 8px;">
                <p class="title m-0"></p>
                <!-- <div class="d-flex">
                    <label class="title m-0">Selected Files:</label>
                    <ng-select [items]="selectedFolderIds"   [(ngModel)]="selectedFolderIds"></ng-select>
                  </div> -->
                <div class="button-group">
                    <button *ngIf="attachmentlist" (click)="confirmSelection()" class="btn btn-primary"  ngbTooltip="Open">Open</button>
                    <button  class="btn btn-danger" (click)="onCancelClick()" ngbTooltip="Cancel">Cancel</button>
                 </div>
              </div></div>
        </div>
    </div>
<!-- </div>     -->
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constant } from 'app/Classes/constant';
import { EncrDecrService } from 'app/Services/encr-decr.service';
import { AuthenticationService } from 'app/auth/service';
import { Status } from 'app/enums';
import {MyImageGalleryService} from 'app/my-image-gallery/my-image-gallery.service'
import { Observable } from 'rxjs';
import {DeleteMultipleInputModel, TblUserFolders} from '../filemanagermodel.model';
import { CustomToastrService } from 'app/Services/custom-toastr.service';
import { E } from 'assets/extensions/extensions';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoadingScreenService } from 'app/Services/loading-screen.service';
import { Title } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SelectFileComponent } from '../select-file/select-file.component';

@Component({
  selector: 'app-upload-manager',
  templateUrl: './upload-manager.component.html',
  styleUrls: ['./upload-manager.component.scss']
})
export class UploadManagerComponent implements OnInit {
  @Input() folders: any[];
  @Output() folderImageClick: EventEmitter<any> = new EventEmitter<any>(); // Event emitter for folder image click
  constructor(public filemanagerService:MyImageGalleryService,
    private EncrDecr: EncrDecrService,
    public auth:AuthenticationService,
    private _toastrService: CustomToastrService,
    private _router: Router,
    private loadingScreenService:LoadingScreenService,
    private dialogRef: MatDialogRef<UploadManagerComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    
    }

  ngOnInit(): void {

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key.toLocaleLowerCase() === "escape") {
        this.onCancelClick();
      }
    });
     this.GetUserFolderData()
  }
  onCancelClick() {
    this.dialogRef.close(false);
  }

  GetUserFolderData(){
    if(this.auth.currentUserValue){
      this.loadingScreenService.startLoading();
    let response: Observable<any>;
    this.filemanagerService.GetUserFolderData(this.auth.currentUserValue.UserDetailsId)
      .subscribe(
        (result) => {
          //console.log(result)
          if (result.status == Status.Success) {
            let data = JSON.parse((this.EncrDecr.get(Constant.EncrDecrKey, result.data)));
            this.loadingScreenService.stopLoading(); 
            this.folders= data.UserFolderListData
            // console.log(this.folders,'this.folders')
          }
          // else if(result.status == Status.Error){
          //   this.loadingScreenService.stopLoading();
          //   this.folders= result.data
          // }
        },
      );
      this.loadingScreenService.stopLoading();
    }
  }


  onFolderImageClick(folder: any) {
    this.folderImageClick.emit(folder);
    // console.log(folder,'folder')
    this.openDialog(SelectFileComponent,folder.UserFolderId)
    //this._router.navigate(['/filemanager/view'], {queryParams: { data: this.EncrDecr.set(Constant.EncrDecrKey,folder.UserFolderId )}});
  }

  openDialog(component: any, _data: any = []) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "66%"
    dialogConfig.height="60%"
    dialogConfig.position={
      top:"5%"
    }
    dialogConfig.data = _data;
    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
       
      }
    );
  }


  
}
